export const Category_List = {
  Activism: "Activism",
  Business: "Business",
  Charity: "Charity",
  ComputerProduct: "Software and Products",
  Engineer: "Engineer",
  Entertainment: "Entertainment",
  Graphic: "Graphic",
  Music: "Music",
  Nonprofit: "Non-profit Affiliations",
  Programming: "Programming",
  ServicePortfolio: "Service Portfolios",
  StudentProject: "Student Projects",
  Video: "Video",
  Others: "Others",
};
