import React, { ReactNode } from "react";

import { Box, styled } from "@mui/material";

const Container = styled(Box)(() => ({
  marginBottom: 32,
  ":last-child": {
    marginBottom: 0,
  },
}));

const AppTreeSectionContainer: React.FC<{ children: ReactNode }> = ({ children }) => {
  return <Container>{children}</Container>;
};

export default AppTreeSectionContainer;
