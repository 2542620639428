import { useParams } from "react-router-dom";

import { useQuery } from "@tanstack/react-query";

import { axios } from "@features/config";

export type PublishDataLinkProps = {
  url: string;
  displayText: string;
};

export enum PublishDataInformationEnum {
  DESCRIPTION = "DESCRIPTION",
  MEMBERS = "MEMBERS",
  LANGUAGE = "LANGUAGE",
  LOCATION = "LOCATION",
  INSTITUTION = "INSTITUTION",
}

type PublishDataProps = {
  attachments: string[];
  information: PublishDataInformationEnum[];
  links: PublishDataLinkProps[];
};

export type PublishProject = {
  id: string;
  publishData: PublishDataProps;
};

const useGetPublishProject = <PublishProjectResp>(params?: any, id?: string) => {
  const { projectId } = useParams();

  const { data, ...rest } = useQuery(["getPublishProject", projectId || id, params], () =>
    axios.get(`/round-table/${projectId || id}/publish`, { params })
  );

  return {
    data: data?.data || {},
    ...rest,
  };
};

export default useGetPublishProject;
