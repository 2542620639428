import * as React from "react";
import * as ReactDOM from "react-dom/client";
import { Provider } from "react-redux";

import { QueryClientProvider } from "@tanstack/react-query";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import relativeTime from "dayjs/plugin/relativeTime";

import queryClient from "@config/queryClient";

import { App } from "./App";
import { store } from "./app/store";
import "./index.css";

dayjs.extend(duration);
dayjs.extend(relativeTime);

const container = document.getElementById("root");
if (!container) {
  throw new Error("Failed to find the root element");
}
const root = ReactDOM.createRoot(container);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <App />
      </QueryClientProvider>
    </Provider>
  </React.StrictMode>
);
