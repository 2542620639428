import { useState } from "react";

import {
  Close as CloseIcon,
  Menu as MenuIcon,
  FacebookOutlined as FacebookIcon,
  LinkedIn as LinkedInIcon,
  Search as SearchIcon
} from "@mui/icons-material";
import XIcon from "@mui/icons-material/X";
import {
  Stack,
  Box,
  Grid,
  IconButton,
  Button,
  useTheme,
  SvgIcon,
  Avatar,
  FormControl,
  OutlinedInput,
  InputAdornment,
  ButtonBase,
} from "@mui/material";

import { ReactComponent as MediumIcon } from "@assets/images/gatherlance-landing/medium.svg";
import { ReactComponent as UnitedKingdomIcon } from "@assets/images/gatherlance-landing/united-kingdom.svg";
import { ReactComponent as UserOctagonIcon } from "@assets/images/gatherlance-landing/user-octagon.svg";

import { useViewport } from "@contexts/ViewportContext";

import Typography from "./GatherlanceTypography";
import { useLocation } from "react-router-dom";
import { PublicPaths } from "@routers/path/PublicPath";

const aboutLinks = [
  { label: "About us", href: "#" },
  { label: "Press center", href: "#" },
  { label: "How Roundtable Works", href: "#" },
];

const supportLinks = [
  { label: "Help Center", href: "#" },
  { label: "Term & Policy", href: "#" },
];

export const Header = () => {
  const [openMobileMenu, setOpenMobileMenu] = useState(false);
  const {
    palette: { common },
  } = useTheme();
  const { isMobile, isTablet, isDesktop } = useViewport();
  const location = useLocation();
  const isFreelanceView = location.pathname === PublicPaths.freelance;
  const isMobileDesign = isMobile || isTablet;

  const renderMenu = () => {
    return (
      <Stack
        direction={isMobileDesign ? "column" : "row"}
        gap={3}
        justifyItems={"center"}
        alignItems={"center"}
      >
        {isMobileDesign ? (
          <FormControl sx={{ m: 1, width: "100%", color: 'white' }} variant="outlined">
            <OutlinedInput
              placeholder="What are you looking for?"
              endAdornment={
                <InputAdornment position="end">
                  <SearchIcon sx={{ color: 'black' }} />
                </InputAdornment>
              }
              sx={{ bgcolor: isFreelanceView ? 'white' : 'black' }}
            />
          </FormControl>
        ) : (
          <SearchIcon sx={{ color: 'white' }} />
        )}
        {isDesktop && isFreelanceView && 
          <Stack flexDirection={"row"} gap={1} borderRadius={'24px'} p={'10px 24px'} bgcolor={'#303030'} color={'white'}>
            <UserOctagonIcon />
            <Typography gap={2}>I’m a freelancer</Typography>
          </Stack>
        }
        {isDesktop && <Avatar />}
      </Stack>
    );
  };

  return (
    <Stack px={{ sm: 3, md: 10 }} bgcolor={isFreelanceView ? '#131314' : 'unset'}>
      <Stack
        flexDirection={"row"}
        justifyContent="space-between"
        alignItems={"center"}
        height={"70px"}
        px={isMobile ? 2 : 0}
        borderBottom={isMobileDesign ? "none" : isFreelanceView ? 'unset' : common.border}
      >
        <Stack>
          <img
            src={isFreelanceView ? "/gatherlance_brand_light.png" : "/gatherlance_brand_dark.png"}
            alt="heading-logo"
            height={isMobileDesign ? 32 : 48}
          />
        </Stack>

        {isMobileDesign ? (
          <IconButton onClick={() => setOpenMobileMenu(!openMobileMenu)} sx={{ px: 0 }}>
            {openMobileMenu ? 
              <CloseIcon sx={{ color: isFreelanceView ? 'white' : 'black' }} /> : 
              <MenuIcon sx={{ color: isFreelanceView ? 'white' : 'black' }} />}
          </IconButton>
        ) : (
          <Stack flexDirection={"row"} gap={2} alignItems="center">
            {renderMenu()}
          </Stack>
        )}
      </Stack>

      {isMobileDesign && openMobileMenu && (
        <Stack rowGap={2} p={3}>
          {renderMenu()}
        </Stack>
      )}
    </Stack>
  );
};

export const Footer = () => {
  const { isMobile, isTablet, isDesktop } = useViewport();
  const isMobileDesign = isMobile || isTablet;

  return (
    <>
      <Box
        display={isMobile ? "block" : "flex"}
        justifyContent="space-between"
        py={{ xs: 5, md: 8 }}
        px={{ xs: 3, md: 10 }}
        bgcolor={"black"}
      >
        <Stack>
          <Box
            component="img"
            src={"/gatherlance_brand_light.png"}
            height={isMobileDesign ? 32 : 40}
            width={"max-content"}
            mb={isMobile ? 3 : 0}
          />
        </Stack>

        <Grid
          container
          columnSpacing={isDesktop ? 10 : 2}
          rowGap={3}
          justifyContent={isMobile ? "start" : "flex-end"}
        >
          <Grid item xs={11} tablet={6} sm={3}>
            <Stack rowGap={2.5}>
              <Typography fontWeight={700} color={"white"}>
                ABOUT
              </Typography>
              <Stack rowGap={1.5}>
                {aboutLinks.map((link, index) => {
                  return (
                    <Typography
                      key={`${index}-${link.label}-about-section`}
                      color={"common.gray3"}
                    >
                      {link.label}
                    </Typography>
                  );
                })}
              </Stack>
            </Stack>
          </Grid>
          <Grid item>
            <Stack rowGap={2.5}>
              <Typography fontWeight={700} color={"white"}>
                SUPPORT
              </Typography>
              <Stack rowGap={1.5}>
                {supportLinks.map((link, index) => {
                  return (
                    <Typography
                      key={`${index}-${link.label}-about-section`}
                      color={"common.gray3"}
                    >
                      {link.label}
                    </Typography>
                  );
                })}
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </Box>
      <Stack py={{ xs: 5, md: 8 }} px={{ xs: 3, md: 10 }} bgcolor={"black"} gap={3}>
        <Stack
          flexDirection={isMobile ? "column" : "row"}
          justifyContent={"space-between"}
          gap={3}
        >
          <Button
            startIcon={
              <SvgIcon>
                <UnitedKingdomIcon />
              </SvgIcon>
            }
            sx={{
              color: "white",
              fontWeight: 400,
              width: "fit-content",
              border: "1px solid white",
              borderRadius: 2,
            }}
          >
            English
          </Button>
          <Stack flexDirection={"row"} alignItems={"center"} gap={2} color={"white"}>
            <LinkedInIcon />
            <FacebookIcon />
            <MediumIcon />
            <XIcon />
          </Stack>
        </Stack>
        <Box
          display="flex"
          flexDirection={isMobile ? "column" : "row"}
          columnGap={6}
          rowGap={1}
        >
          <Typography color={"white"}>© {new Date().getFullYear()} Roundtable</Typography>
          <Box
            display="flex"
            flexDirection={isMobile ? "column" : "row"}
            columnGap={3}
            rowGap={1}
          >
            <Typography color={"common.bellflowerBlue"}>Terms of Use</Typography>
            <Typography color={"common.bellflowerBlue"}>Cookie Policy</Typography>
            <Typography color={"common.bellflowerBlue"}>Privacy Notice</Typography>
          </Box>
        </Box>
      </Stack>
    </>
  );
};
