import { FC, ReactNode, useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";

import useGetNotifications from "@api/user/useGetNotifications";
import { PublicPaths } from "@routers/path/PublicPath";

// import Helmet from "react-helmet";
import { useGetUnreadP2PIds } from "@pages/messenger/ConversationList/hooks";

const FAVICON = "/favicon_head.png";
const FAVICON_NOTI = "/favicon_head_noti.png";

interface IDocumentHeaderProps {
  children?: ReactNode;
}

const DocumentHeader: FC<IDocumentHeaderProps> = ({ children }) => {
  const useGetUnreadP2PChat = useGetUnreadP2PIds();
  const location = useLocation();
  const { data: notificationData } = useGetNotifications({
    enabled: true,
    refetchInterval: 6000,
  });

  const unreadNotificationCount = useMemo(() => {
    return notificationData.reduce((accumulator, currentValue) => {
      return accumulator + (currentValue.isRead ? 0 : 1);
    }, 0);
  }, [notificationData]);

  useEffect(() => {
    // const elements = document.getElementById( "favico" );
    // const elements: HTMLLinkElement = document.querySelector("link[rel~='icon']");
    // elements.remove();

    // const link = document.createElement('link');
    const link: HTMLLinkElement = document.querySelector("link[rel~='icon']");
    link.rel = "icon";
    if (location.pathname === PublicPaths.projectPage) {
      return;
    }
    if (useGetUnreadP2PChat?.length > 0 || unreadNotificationCount) {
      link.setAttribute("href", `${FAVICON_NOTI}?v=${Date.now()}`);
    } else {
      link.setAttribute("href", `${FAVICON}?v=${Date.now()}`);
    }
    // document.getElementsByTagName('head')[0].prepend(link);
  }, [location.pathname, useGetUnreadP2PChat.length, unreadNotificationCount]);

  return <></>;
};

export default DocumentHeader;
