import React from "react";

import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Skeleton from "@mui/material/Skeleton";

export type LoadingType = "wave" | "circular";

interface LoadingProps {
  type?: LoadingType;
  circularSize?: number;
}

const Loading: React.FC<LoadingProps> = ({ type = "wave", circularSize }) => {
  let renderedComponent;

  switch (type) {
    case "circular":
      renderedComponent = renderCircularLoading(circularSize);
      break;
    default:
      renderedComponent = renderWaveLoading();

      break;
  }

  return renderedComponent;
};

const renderCircularLoading = (size?: number) => {
  return (
    <Box className="d-flex justify-center">
      <CircularProgress color="inherit" className="ace" {...(size && { size })} />
    </Box>
  );
};

const renderWaveLoading = () => {
  return (
    <Box sx={{ width: "100%" }}>
      <Skeleton />
      <Skeleton animation="wave" />
      <Skeleton animation={false} />
    </Box>
  );
};

export default Loading;
