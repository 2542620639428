import { useCallback } from "react";

import { Logout } from "@mui/icons-material";
import { Avatar, styled, Link, Stack } from "@mui/material";
import { Box } from "@mui/material";

import useGetAccountInfo from "@hooks/useGetAccountInfo";

import { useAppDispatch } from "@app/hooks";

import { signOut } from "@features/auth/slice";

import useControlSidebar from "../hooks/useControlSidebar";
import { DrawerFooterHeight, iconStyles } from "../styles";

const Container = styled(Box)<{ sidebarOpen: boolean }>(({ sidebarOpen }) => ({
  display: "flex",
  marginTop: "auto",
  width: "100%",
  padding: sidebarOpen ? "24px 24px 0 24px" : 0,
  justifyContent: "space-between",
  alignItems: "center",
  borderTop: "1px solid#E2E8F0",
  height: DrawerFooterHeight,
  ...(!sidebarOpen && {
    justifyContent: "center",
  }),
}));

const AvatarContainer = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
}));

const AccountName = styled(Link)(({ theme }) => ({
  color: theme.palette.common.black,
  fontSize: 14,
  fontWeight: 600,
}));

const AccountEmail = styled(Link)(({ theme }) => ({
  fontSize: 10,
  color: theme.palette.common.gray6,
  fontWeight: 400,
}));

const AccountAvatar = styled(Avatar)(() => ({
  width: 40,
  aspectRatio: 1,
}));

const AccountInfoContainer = styled(Stack)(() => ({
  padding: "4px 0",
  marginLeft: 8,
}));

const LogoutButton = styled(Logout)(() => ({
  ...iconStyles,
  cursor: "pointer",
}));

const UserSidebar = () => {
  const { user } = useGetAccountInfo();
  const [open] = useControlSidebar();
  const dispatch = useAppDispatch();

  const handleLogout = useCallback(() => {
    dispatch(signOut());
  }, []);

  return (
    <Container sidebarOpen={open}>
      {open && (
        <AvatarContainer>
          <AccountAvatar src={user?.avatar} />
          <AccountInfoContainer>
            <AccountName href={"/my-account"} underline="hover">
              {user?.name}
            </AccountName>
            <AccountEmail href={"/my-account"} underline="hover">
              {user?.email}
            </AccountEmail>
          </AccountInfoContainer>
        </AvatarContainer>
      )}
      <LogoutButton onClick={handleLogout} />
    </Container>
  );
};

export default UserSidebar;
