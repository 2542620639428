import { Skeleton } from "@mui/material";
import React from "react";

const LoadingPage = () => {
  return (
    <>
      <Skeleton />
      <Skeleton animation="wave" />
      <Skeleton animation={false} />
    </>
  )
}

export default LoadingPage
