import { Stack } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { Outlet, useLocation, useNavigate, useSearchParams } from "react-router-dom";

import { Header, Footer } from './HeaderAndFooter';

const PublicPageLayout = () => {
  return (
    <div>
      <Stack sx={{ position: "sticky", top: 0, width: "100%", bgcolor: "white", zIndex: 10 }}>
        <Header />
      </Stack>
      <Outlet />
      <Footer />
    </div>
  )
}

export default PublicPageLayout;
