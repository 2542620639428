import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";

import { ErrorResponse, axios } from "@features/config";

import { buildQueryString } from "@utils/index";

export type User = {
  id: string;
  username: string;
  avatar?: string;
  name: string;
  email: string;
  gender: string;
  level: string;
  dateOfBirth: string;
  phoneNumber: string;
  location: string;
  workingSchedule: string;
  createdAt: string;
};

export interface UsersQuery {
  page: number;
  pageSize: number;
  email?: string;
  name?: string;
}

export const getUsers = createAsyncThunk(
  "getUsers",
  async ({ page = 0, pageSize = 10, email, name }: UsersQuery, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(
        `/users${buildQueryString({
          page: page + 1,
          pageSize,
          email,
          name,
        })}`
      );

      return data;
    } catch (error) {
      const err = error as AxiosError<ErrorResponse>;

      if (err.response) {
        const {
          response: { data },
        } = err;

        return rejectWithValue(data?.message);
      }

      return rejectWithValue(err.message);
    }
  }
);
