import { Box, styled } from "@mui/material";

const DotNotification = styled(Box)(({ theme }) => ({
  width: 8,
  height: 8,
  aspectRatio: 1,
  background: theme.palette.primary.main,
  borderRadius: "100%",
}))

export default DotNotification
