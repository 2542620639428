import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

import { DrawerHeaderHeight } from "../styles";

const DrawerHeader = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  position: "relative",
  height: DrawerHeaderHeight,
  ...theme.mixins.toolbar,
}));

export default DrawerHeader;
