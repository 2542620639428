import { Box, CssBaseline, Stack } from "@mui/material";
import { Outlet } from "react-router-dom";
import { Footer, Header } from "../components/HeaderAndFooter";

const GatherlanceLandingLayout = () => {
  return (
    <Stack height={'100vh'}>
      <CssBaseline />
      <Stack
        sx={{ position: "sticky", top: 0, width: "100%", bgcolor: "white", zIndex: 10 }}
      >
        <Header />
      </Stack>

      <Box flex={1}>
        <Outlet />
      </Box>
      <Footer />
    </Stack>
  )
};

export default GatherlanceLandingLayout;