import { createAction, createSlice } from "@reduxjs/toolkit";

import {
  signIn,
  signUp,
  forgotPassword,
  resetPassword,
  updateUserInfo,
} from "@features/auth/actions";

import { User } from "@dto/user";

export type UserInfo = {
  accessToken: string;
  idToken: string;
  firebaseToken: string;
  user: User;
};

export const getUserInfoFromLocalStorage = (): UserInfo | null => {
  let userInfo = null;

  try {
    const persistedState = localStorage.getItem("userInfo");
    if (persistedState) {
      userInfo = JSON.parse(persistedState);
    }
  } catch (e) {
    userInfo = null;
  }

  return userInfo;
};

export const signOut = createAction("auth/logout");

type AuthSliceState = {
  loading: boolean;
  error: string | null;
  success: boolean;
  userInfo: UserInfo | null;
};

const initialState: AuthSliceState = {
  loading: false,
  error: null,
  success: false,
  userInfo: getUserInfoFromLocalStorage(),
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    resetApiState: (state) => {
      state.success = false;
      state.loading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(signOut, () => {
      localStorage.removeItem("userInfo");
      localStorage.removeItem("user");
      return { ...initialState, userInfo: null };
    });

    builder.addCase(signIn.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(signIn.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.userInfo = payload?.data;
    });

    builder.addCase(signIn.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload as string;
    });

    builder.addCase(signUp.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(signUp.fulfilled, (state) => {
      state.loading = false;
      state.success = true;
    });

    builder.addCase(signUp.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload as string;
    });

    builder.addCase(forgotPassword.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(forgotPassword.fulfilled, (state) => {
      state.loading = false;
      state.success = true;
    });

    builder.addCase(forgotPassword.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload as string;
    });

    builder.addCase(resetPassword.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(resetPassword.fulfilled, (state) => {
      state.loading = false;
      state.success = true;
    });

    builder.addCase(resetPassword.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload as string;
    });

    builder.addCase(updateUserInfo.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(updateUserInfo.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.success = true;
      state.userInfo.user = payload?.data;
    });

    builder.addCase(updateUserInfo.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload as string;
    });
  },
});

const { reducer } = authSlice;
export default reducer;

export const { resetApiState } = authSlice.actions;
