import { useCallback, useEffect } from "react";

import { useAppDispatch, useAppSelector } from "@app/hooks";

import { sidebarActions } from "@features/sidebar/slice";

const useControlSidebar = (initial?: boolean) => {
  const open = useAppSelector((state) => state.sideBar.open);

  const dispatch = useAppDispatch();

  const setSidebarOpen = useCallback(
    (open: boolean) => {
      dispatch(
        sidebarActions.setSidebarOpen({
          open,
        })
      );
    },
    [open]
  );

  useEffect(() => {
    if (initial !== undefined) {
      setSidebarOpen(initial);
    }
  }, []);

  return [open, setSidebarOpen] as [typeof open, typeof setSidebarOpen];
};

export default useControlSidebar;
