import React, { ReactNode, useState, useMemo, useEffect } from "react";

import { ExpandMore as ExpandMoreIcon, Search as SearchIcon } from "@mui/icons-material";
import {
  Button,
  Popover,
  FormControlLabel,
  Stack,
  Checkbox,
  Divider,
  Typography,
  useTheme,
  TextField,
  Chip,
} from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";

import EllipsisContent from "@components/EllipsisContent";

type RowItem = {
  name: string;
  group?: string;
  key?: string | number;
  displayGroup?: boolean;
  id?: string | number;
};

type CategoryOption = {
  name: string;
  tags: string[];
};
interface IDropdownCheckboxes {
  label?: string;
  options?: string[] | CategoryOption[];
  onChangeData?: (data?: string[] | any) => void;
  optionIcon?: ReactNode;
  optionDataFormat?: "categoryWithTag" | "";
  filterSelectedWhenOptionsChange?: boolean;
  defaultValues?: string[];
}

const DropdownCheckboxes = ({
  label,
  options = [],
  optionIcon,
  onChangeData,
  optionDataFormat,
  filterSelectedWhenOptionsChange,
  defaultValues,
}: IDropdownCheckboxes) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [selectedItems, setSelectedItems] = useState<string[]>(defaultValues || []);
  const [searchVal, setSearchVal] = useState("");
  const {
    palette: { primary, common },
  } = useTheme();

  useEffect(() => {
    if (filterSelectedWhenOptionsChange) {
      const currentSelectedItems = [...selectedItems];
      const unselectedItems = currentSelectedItems.filter(
        (item: string) => !(options as string[]).includes(item)
      );
      if (unselectedItems?.length > 0 && onChangeData) {
        handleCheckMulti(false, unselectedItems);
      }
    }
  }, [options]);

  const open = Boolean(anchorEl);

  const formatedOptions: RowItem[] = useMemo(() => {
    return optionDataFormat === "categoryWithTag"
      ? (options as CategoryOption[])?.reduce((a, o: any) => {
          const optsWithGroup = o?.tags?.map((t: string) => ({
            name: t,
            group: o?.name,
          }));
          return [...a, ...optsWithGroup];
        }, [])
      : (options as string[])?.map((o) => ({ name: o, key: o }));
  }, [options]);

  const searchedOptions = formatedOptions
    ?.filter((opt) => opt.name?.trim())
    ?.filter((opt) => opt.name.toLowerCase().includes(searchVal?.toLowerCase()))
    ?.map((opt, i, opts) => {
      const displayGroup = opts?.[i - 1]?.group !== opt?.group;
      return {
        ...opt,
        displayGroup,
        id: i,
      };
    });

  const columns: GridColDef[] = useMemo(() => {
    return [
      {
        field: "name",
        headerName: "Vote Weight",
        width: 358,
        hideable: false,
        filterable: false,
        renderCell: (params) => {
          return (
            <Stack width={"100%"} px={1.5}>
              {params?.row?.displayGroup && (
                <Typography fontSize={"16px"} fontWeight={"bold"} my={0.6}>
                  {params?.row?.group}
                </Typography>
              )}
              <Stack direction={"row"} alignItems={"center"} spacing={1.5} my={0.6}>
                {optionIcon}
                <FormControlLabel
                  sx={{
                    justifyContent: "space-between",
                    marginLeft: 0,
                    width: "100%",
                    textTransform: "capitalize",
                  }}
                  labelPlacement="start"
                  label={
                    <EllipsisContent
                      fontSize={16}
                      lineHeight={1.5}
                      fontWeight={"normal"}
                      numberOfLines={"unset"}
                      sx={{ mt: 0, wordBreak: "break-word" }}
                      content={params?.row?.name}
                    />
                  }
                  control={
                    <Checkbox
                      checked={selectedItems.includes(params?.row?.name)}
                      onChange={(e) =>
                        handleCheckMulti(e.target.checked, [params?.row?.name])
                      }
                      value={params?.row?.name}
                    />
                  }
                />
              </Stack>
            </Stack>
          );
        },
      },
    ];
  }, [searchedOptions]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const filterGroup = (optsSelected?: string[]) => {
    const groupList = (options as CategoryOption[]).reduce((a, { name, tags }) => {
      return tags.some((t) => optsSelected.includes(t)) ? [...a, name] : a;
    }, []);

    return groupList;
  };
  const handleCheckMulti = (checked: boolean, values: string[]) => {
    let currentSelected = [...selectedItems];
    if (checked) {
      currentSelected.push(...values);
      setTimeout(() => {
        document?.querySelector(".selected-chip:last-child")?.scrollIntoView();
      }, 150);
    } else {
      currentSelected = currentSelected.filter((v) => !values.includes(v));
    }
    setSelectedItems(currentSelected);

    if (onChangeData) {
      onChangeData(currentSelected);
    }
  };

  // const handleCheck = (checked: boolean, value: string) => {
  //   let currentSelected = [...selectedItems];

  //   if (checked) {
  //     currentSelected.push(value);
  //     setTimeout(() => {
  //       document?.querySelector(".selected-chip:last-child")?.scrollIntoView()
  //     }, 150);
  //   } else {
  //     currentSelected = currentSelected.filter(v => v !== value)
  //   }

  //   setSelectedItems(currentSelected);
  //   if (optionDataFormat === "categoryWithTag") {
  //     const group = filterGroup(currentSelected || [])
  //     if (onChangeData) {
  //       return onChangeData({ tags: currentSelected, categories: group })
  //     }
  //   }

  //   if (onChangeData) {
  //     onChangeData(currentSelected);
  //   }
  // };

  // const handleClearAll = () => {
  //   setSelectedItems([]);
  //   onChangeData([]);
  // }

  return (
    <div>
      <Button
        onClick={handleClick}
        sx={{
          borderRadius: 49,
          p: "8px 14px",
          border: `1px solid ${open ? primary.main : common.gray4}`,
        }}
      >
        <Typography color={open ? primary.main : "initial"}>
          {label} {selectedItems?.length > 0 && `(${selectedItems.length})`}
        </Typography>
        <ExpandMoreIcon sx={{ fill: open ? primary.main : common.gray4, ml: "4px" }} />
      </Button>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        sx={{ mt: 1.5 }}
        slotProps={{
          paper: {
            sx: {
              boxShadow: "1px 1px 4px 0px rgba(0, 0, 0, 0.10)",
              border: common.border,
              width: { lg: 360 },
            },
          },
        }}
      >
        <Stack p={2}>
          <TextField
            sx={{
              "& fieldset": {
                borderRadius: 9,
              },
              "& input": {
                padding: "12px 16px 12px 12px",
              },
              height: "48px",
              "svg": {
                color: common.doverGrey,
              },
            }}
            InputProps={{
              startAdornment: <SearchIcon />,
            }}
            placeholder={`Search ${label?.toLowerCase()}...`}
            value={searchVal}
            onChange={(e) => setSearchVal(e.target.value)}
          />
        </Stack>
        {selectedItems?.length > 0 && (
          <>
            <Stack
              direction={"row"}
              alignItems={"center"}
              maxHeight={72}
              overflow={"auto"}
              mt={0.5}
              mb={1.5}
              px={3}
              mx={1}
              flexWrap={"wrap"}
              gap={1}
            >
              {selectedItems?.map((item, i) => (
                <Chip
                  key={i}
                  className="selected-chip"
                  label={item}
                  sx={{
                    bgcolor: "#EDF2F7",
                    textTransform: "capitalize",
                    "& .MuiChip-deleteIcon": { color: common.featherSoftBlue },
                  }}
                  onDelete={() => handleCheckMulti(false, [item])}
                />
              ))}
            </Stack>
            <Divider sx={{ mx: 2 }} />
          </>
        )}

        {searchedOptions?.length > 0 && (
          <DataGrid
            rows={searchedOptions}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { pageSize: 20 },
              },
            }}
            // paginationMode="server"
            // pageSizeOptions={[20]}
            sx={{
              width: "100%",
              maxHeight: 320,
              overflow: "auto",
              "&.MuiDataGrid-withBorderColor, &.MuiDataGrid-root .MuiDataGrid-withBorderColor":
                {
                  border: "none",
                  borderColor: "transparent",
                },
            }}
            disableRowSelectionOnClick
            slots={{
              columnHeaders: () => null,
            }}
            hideFooter={formatedOptions?.length <= 20}
            getRowHeight={() => "auto"}
          />
        )}
      </Popover>
    </div>
  );
};

export default DropdownCheckboxes;
