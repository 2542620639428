import { useCallback, useEffect, useMemo, useState } from "react";
import { AiFillCaretDown, AiFillCaretRight } from "react-icons/ai";
import { useLocation, useParams } from "react-router-dom";

import useGetJoinedRoundtables from "@api/roundtable/useGetJoinedRoundtables";
import { Outbound as OutboundIcon } from "@mui/icons-material";
import { Avatar } from "@mui/material";
import AppRoutes, { AppSectionKey, AppSections } from "@routers/AppRoutes";
import { SidebarKeys } from "@routers/const";
import { ProtectedPaths } from "@routers/path/ProtectedPath";
import { AppRoute } from "@routers/type";

import { useAppSelector } from "@app/hooks";

import {
  getAbbreviation, // generateHSL
} from "@utils/index";

import { ReactComponent as RoundtableProjectsLogo } from "@assets/roundtable/SVG/Roundtables/RoundtableProjects-Mark-MainColor.svg";

import { Roundtable } from "@dto/roundtable";
import { UserRole } from "@dto/user";

import { RoundtableTypeEnum } from "@@types/type";

import { SIDEBAR_KEY_STORAGE } from "../const";
import useControlSidebar from "./useControlSidebar";

export type NavItem = Extract<AppRoute, { inSidebar: true }> & { className?: string };

export const useNavItems = () => {
  const { roundtableId, projectId } = useParams();
  const { pathname } = useLocation();
  const userInfo = useAppSelector((state) => state.auth.userInfo);
  const isAdminUser = userInfo?.user?.role === UserRole.ADMIN;

  const [open] = useControlSidebar();
  const {
    data: roundtableList,
    isSuccess,
    isLoading,
  } = useGetJoinedRoundtables({
    userId: userInfo?.user?.id,
    pageSize: 9999,
    type: [RoundtableTypeEnum.TEAM],
  });

  const [expandItems, setExpandItems] = useState<string[]>(
    sessionStorage.getItem(SIDEBAR_KEY_STORAGE.EXPANDED_ITEMS)
      ? JSON.parse(sessionStorage.getItem(SIDEBAR_KEY_STORAGE.EXPANDED_ITEMS))
      : []
  );

  const [selectedSidebarItem, setSelectedSidebarItem] = useState<string>(
    sessionStorage.getItem(SIDEBAR_KEY_STORAGE.SELECTED_ITEM) || ""
  );

  const generateSidebarItems = useCallback(
    (roundTables?: Roundtable[]) => {
      const sideBarItems = Object.keys(AppSections).reduce(
        (acc, key) => {
          const sectionKey = key as AppSectionKey;
          acc[sectionKey] = {
            sectionLabel: "",
            items: [],
          };

          acc[sectionKey]["sectionLabel"] = AppSections[sectionKey as AppSectionKey];

          !isAdminUser &&
            AppRoutes.protected.forEach((appProtectedRoute) => {
              if (!appProtectedRoute.inSidebar) {
                return;
              }

              if (!(appProtectedRoute.section === AppSections[key as AppSectionKey])) {
                return;
              }

              const route = appProtectedRoute;

              if (roundTables?.length && route.key === "roundtableList") {
                if (isLoading) {
                  return (route.nestedPaths = [
                    {
                      key: key as any,
                      path: "/#",
                      section: AppSections.Communication,
                      sidebarLabel: "",
                      sidebarIcon: null,
                      inSidebar: true,
                      component: null,
                      nestedPaths: [],
                    },
                  ]);
                }

                if (open) {
                  route.nestedPaths = roundTables.map((roundtable) => {
                    const key = `${SidebarKeys.roundtableItem}:${roundtable.id}`;
                    const checkExpanded = expandItems.includes(key);
                    const checkSelected = selectedSidebarItem === key;

                    return {
                      key: key as any,
                      path: ProtectedPaths.chatRoundTableId.replace(
                        ":roundtableId",
                        roundtable.id
                      ) as any,
                      className:
                        checkSelected && checkExpanded
                          ? ""
                          : checkExpanded
                          ? "roundTable-item"
                          : "",
                      section: AppSections.Communication,
                      sidebarLabel: roundtable.title,
                      // sidebarIcon: checkExpanded ? AiFillCaretDown : AiFillCaretRight,
                      sidebarIcon: () => (
                        <Avatar
                          sx={{
                            // bgcolor: generateHSL(getAbbreviation(roundtable.title)),
                            width: 31,
                            height: 31,
                            fontSize: 16,
                          }}
                          alt={roundtable.title}
                          src={roundtable.avatar}
                        >
                          {!roundtable.avatar && getAbbreviation(roundtable.title)}
                        </Avatar>
                      ),
                      expandIcon:
                        roundtable.projects?.length > 0 &&
                        (checkExpanded ? AiFillCaretDown : AiFillCaretRight),
                      inSidebar: true,
                      component: null,
                      nestedPaths: roundtable.projects?.map?.((project) => ({
                        key: `${SidebarKeys.roundtableItem}:${roundtable.id}:${project.id}` as any,
                        path: project.id,

                        section: AppSections.Communication,
                        sidebarLabel: project.title,
                        sidebarIcon: project.type?.startsWith("EXTERNAL_")
                          ? OutboundIcon
                          : () => (
                              <Avatar
                                sx={{
                                  // bgcolor: generateHSL(getAbbreviation(roundtable.title)),
                                  width: 31,
                                  height: 31,
                                  fontSize: 16,
                                }}
                                variant="rounded"
                                alt={project.title}
                                src={project.avatar}
                              >
                                {!project.avatar && getAbbreviation(project.title)}
                              </Avatar>
                            ),
                        inSidebar: true,
                        component: null,
                        className: "roundTable-item-project",
                      })),
                    };
                  }) as NavItem[];
                } else {
                  route.nestedPaths = [];
                }
              }

              acc[sectionKey]["items"].push(route);
            });

          isAdminUser &&
            AppRoutes.admin.forEach((appAdminRoute) => {
              if (
                !appAdminRoute.inSidebar ||
                !(appAdminRoute.section === AppSections[key as AppSectionKey])
              ) {
                return;
              }

              acc[sectionKey]["items"].push(appAdminRoute);
            });

          return acc;
        },
        {} as Record<
          AppSectionKey,
          {
            sectionLabel: string;
            items: NavItem[];
          }
        >
      );

      return sideBarItems;
    },
    [expandItems, selectedSidebarItem, open, isLoading]
  );

  const sideBarItems = useMemo(() => generateSidebarItems(), [generateSidebarItems]);
  const [navItems, setNavItems] = useState(sideBarItems);
  const [highlightHandler, setHighlightHandler] = useState(true);

  const handleChangeExpandSidebarItems = useCallback((nodeIds: string[]) => {
    setExpandItems(nodeIds);
    sessionStorage.setItem(SIDEBAR_KEY_STORAGE.EXPANDED_ITEMS, JSON.stringify(nodeIds));
  }, []);

  const handleFocusSidebarItem = useCallback((nodeId: string) => {
    setSelectedSidebarItem(nodeId);
    sessionStorage.setItem(SIDEBAR_KEY_STORAGE.SELECTED_ITEM, nodeId);
  }, []);

  useEffect(() => {
    if (isSuccess) {
      setNavItems(generateSidebarItems(roundtableList));
    }
  }, [roundtableList, generateSidebarItems, isSuccess]);

  useEffect(() => {
    const selectedItem = sessionStorage.getItem(SIDEBAR_KEY_STORAGE.SELECTED_ITEM);

    if ((pathname === "" || pathname === "/") && selectedItem !== "/") {
      // return handleFocusSidebarItem("dashboard");
    }

    if (!roundtableId) {
      return handleFocusSidebarItem(pathname);
    }

    // if (roundtableId && projectId) {
    //   const nodeId = `${SidebarKeys.roundtableItem}:${roundtableId}:${projectId}`;
    //   return handleFocusSidebarItem(nodeId);
    // }

    // if (roundtableId && !projectId) {
    //   handleFocusSidebarItem(`${SidebarKeys.roundtableItem}:${roundtableId}`);
    // }
  }, [pathname]);

  // highlight the roundtable/project
  useEffect(() => {
    if (
      !roundtableList?.length ||
      !isSuccess ||
      (!roundtableId && !projectId) ||
      !highlightHandler
    ) {
      return;
    }

    const newExpandItems = [...(expandItems || [])];
    !newExpandItems.includes(SidebarKeys.roundtableList) &&
      newExpandItems.push(SidebarKeys.roundtableList);
    !newExpandItems.includes(`${SidebarKeys.roundtableItem}:${roundtableId}`) &&
      roundtableId &&
      newExpandItems.unshift(`${SidebarKeys.roundtableItem}:${roundtableId}`);

    const newSelectedItem = [];
    roundtableId && newSelectedItem.push(roundtableId);
    projectId && newSelectedItem.push(projectId);

    if (newExpandItems?.length > expandItems.length) {
      handleChangeExpandSidebarItems(newExpandItems);
    }
    if (newSelectedItem?.length > 0) {
      handleFocusSidebarItem(
        `${SidebarKeys.roundtableItem}:${newSelectedItem.join(":")}`
      );
    }
    setHighlightHandler(false);
  }, [pathname, roundtableList, isSuccess]);

  return [
    navItems,
    {
      handleChangeExpandSidebarItems,
      handleFocusSidebarItem,
      expandItems,
      selectedSidebarItem,
    },
  ] satisfies [
    typeof navItems,
    {
      handleChangeExpandSidebarItems: typeof handleChangeExpandSidebarItems;
      handleFocusSidebarItem: typeof handleFocusSidebarItem;
      expandItems: typeof expandItems;
      selectedSidebarItem: typeof selectedSidebarItem;
    }
  ];
};
