import { useEffect, useState, useRef } from "react";

import { throttle } from "lodash";

const useWindowScroll = (waitingMillisecond?: number): number => {
  const [scrollPosition, setScrollPosition] = useState(0);

  const handleScroll = throttle(() => {
    setScrollPosition(window.scrollY);
  }, waitingMillisecond ?? 100);

  const requestRef = useRef<number>();

  const animate = () => {
    handleScroll();
    requestRef.current = requestAnimationFrame(animate);
  };

  useEffect(() => {
    requestRef.current = requestAnimationFrame(animate);

    return () => {
      cancelAnimationFrame(requestRef.current!);
    };
  }, []);

  return scrollPosition;
};

export default useWindowScroll;
