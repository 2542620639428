import { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";

import { QueryFunctionContext, useInfiniteQuery, useQuery } from "@tanstack/react-query";
import { isEqual } from "lodash";

import { axios } from "@features/config";

import { PublishedProject } from "@dto/publishedProject";

type ProjectType = "popular" | "freshNew" | "default";

type Response = {
  data: PublishedProject[];
  totalItems: string;
  currentPage: string;
};

export interface PublishedProjectRequest {
  title: string;
  page: number;
  pageSize: number;
  type?: ProjectType;
  tags?: string[];
  category?: string[];
  institutions?: string[];
  locations?: string[];
  orderByTime?: "ASC" | "DESC";
  order?: {
    field: string;
    by: string;
  };
  excludeCategory: string;
  users?: string;
}

const getPublishedProjects = async (
  pageParam: number,
  {
    tags,
    category: categories,
    type,
    title,
    page = 0,
    pageSize = 5,
    institutions,
    locations,
    orderByTime,
    order,
    excludeCategory,
    users
  }: Partial<PublishedProjectRequest>
): Promise<Response> => {
  const orderByTimeBasedOnType =
    type === "popular" ? "ASC" : type === "freshNew" ? "DESC" : undefined;

  const response = await axios.get(`/round-tables/publishes`, {
    params: {
      title,
      pageSize,
      orderBy: order?.by || "",
      orderField: order?.field || "",
      page: pageParam + 1 || page + 1,
      orderByTime: orderByTime || orderByTimeBasedOnType,
      tag: tags?.length ? tags.join(",") : undefined,
      category: categories?.length ? categories.join(",") : undefined,
      institution: institutions?.length ? institutions.join(",") : undefined,
      location: locations?.length ? locations.join(",") : undefined,
      excludeCategory: excludeCategory || "",
      users
    },
  });

  return response.data.data;
};

const useGetPublishedProjects = (initParams: Partial<PublishedProjectRequest>) => {
  const [params, setParams] = useState(initParams);
  const equalNewparams = isEqual(initParams, params);
  const { categoryName: categoryNameParam } = useParams();

  const { data, refetch, ...rest } = useInfiniteQuery(
    ["getPublishedProjects", params as string],
    ({ pageParam }) => {
      return getPublishedProjects(pageParam, params);
    },
    {
      getNextPageParam: (lastPage, pages) => {
        if (pages.length * params.pageSize >= +lastPage?.totalItems) {
          return undefined;
        }

        return pages.length;
      },
    }
  );

  useEffect(() => {
    refetch();
  }, [refetch]);

  const handleChangeParams = useCallback((params: Partial<PublishedProjectRequest>) => {
    setParams((prev) => ({ ...prev, ...params }));
  }, []);

  const publishedProjects = useMemo(() => {
    const formattedData = data?.pages?.flatMap((page) => page?.data) || [];

    return !!formattedData?.length ? formattedData : [];
  }, [data]);

  useEffect(() => {
    if (categoryNameParam && !equalNewparams) {
      setParams(initParams);
    }
  }, [categoryNameParam]);

  return {
    data: publishedProjects,
    totalItems: +data?.pages[data.pages.length - 1]?.totalItems || 0,
    params,
    refetch,
    handleChangeParams,
    ...rest,
  };
};

export default useGetPublishedProjects;
