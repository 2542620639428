import { createSlice } from "@reduxjs/toolkit";

import { ManagementRoundTable, getManagementRoundTables } from "./actions";

type ManagementRoundTableSliceState = {
  loading: boolean;
  error: string | null;
  success: boolean;
  managementRoundTables: ManagementRoundTable[];
  totalItems: number;
};

const initialState: ManagementRoundTableSliceState = {
  loading: false,
  error: null,
  success: false,
  managementRoundTables: [],
  totalItems: 0,
};

const managementRoundTableSlice = createSlice({
  name: "managementRoundTable",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getManagementRoundTables.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(getManagementRoundTables.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.managementRoundTables = payload?.data?.data;
      state.totalItems = payload?.data?.totalItems;
    });

    builder.addCase(getManagementRoundTables.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload as string;
    });
  },
});

const { reducer } = managementRoundTableSlice;

export default reducer;
