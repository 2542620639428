import React from "react";
import { useNavigate } from "react-router-dom";

import useGetSearchCriteria from "@api/roundtable/useGetSearchCriteria";
import { Box, Menu, MenuItem } from "@mui/material";
import { PublicPaths } from "@routers/path/PublicPath";
import { get } from "lodash";

import Button from "@components/Button";

type TSearchCriteria = ReturnType<typeof useGetSearchCriteria>;

export const CategoryMenu = () => {
  const navigate = useNavigate();
  const searchCriteria = useGetSearchCriteria({
    enabled: true,
  });
  const categories = get(searchCriteria, "data.categories") || [];
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const onSelectCategory =
    (category: TSearchCriteria["data"]["categories"][0]) =>
    (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
      e.stopPropagation();
      navigate(PublicPaths.projectPage + "/" + encodeURIComponent(category.name));
      handleClose();
    };

  return (
    <Box>
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        variant="text"
        color={open ? undefined : "inherit"}
        sx={{
          fontWeight: "normal",
          padding: "5px",
          fontSize: 15,
        }}
      >
        Category
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {categories
          .filter((item) => item.isDisplay)
          .map((item, index) => {
            return (
              <MenuItem
                key={index}
                onClick={onSelectCategory(item)}
                sx={{
                  fontWeight: "bold",
                }}
              >
                {item.name}
              </MenuItem>
            );
          })}
      </Menu>
    </Box>
  );
};
