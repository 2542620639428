import { useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";

import {
  Close as CloseIcon,
  Menu as MenuIcon,
  Logout as LogoutIcon,
  FacebookOutlined as FacebookIcon,
  LinkedIn as LinkedInIcon,
} from "@mui/icons-material";
import XIcon from "@mui/icons-material/X";
import {
  Stack,
  Box,
  Grid,
  IconButton,
  Button,
  Typography,
  SvgIcon,
  useTheme,
} from "@mui/material";
import { AuthPath } from "@routers/path/AuthPath";
import { PublicPaths } from "@routers/path/PublicPath";

import { useAppDispatch, useAppSelector } from "@app/hooks";

import { signOut } from "@features/auth/slice";

import { addNewUrlParams } from "@utils/helpers/stringHelper";

import { ReactComponent as MediumIcon } from "@assets/images/gatherlance-landing/medium.svg";
import { ReactComponent as UnitedKingdomIcon } from "@assets/images/gatherlance-landing/united-kingdom.svg";
import RoundtablesHorizontalMainColor from "@assets/roundtable/PNG/Roundtables/Roundtables-Horizontal-MainColor.png";

import { useViewport } from "@contexts/ViewportContext";

const aboutLinks = [
  { label: "About us", href: "#" },
  { label: "Press center", href: "#" },
  { label: "How Roundtable Works", href: "#" },
];

const supportLinks = [
  { label: "Help Center", href: "#" },
  { label: "Term & Policy", href: "#" },
];

export const Header = () => {
  const [openMobileMenu, setOpenMobileMenu] = useState(false);
  const {
    palette: { common },
  } = useTheme();
  const { userInfo } = useAppSelector((state) => state.auth);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isLoggedIn = useMemo(() => {
    if (userInfo) {
      return true;
    }

    return false;
  }, [userInfo]);
  const { isMobile, isTablet, isDesktop } = useViewport();
  const isMobileDesign = isMobile || isTablet;

  const handleClickSignInAndSignOut = () => {
    if (isLoggedIn) {
      dispatch(signOut());
      navigate(AuthPath.signIn);
    }

    return navigate(
      addNewUrlParams(AuthPath.signIn, {
        redirectTo: PublicPaths.indexPage,
      })
    );
  };

  return (
    <Stack px={{ sm: 3, md: 10 }} borderBottom={common.border}>
      <Stack
        flexDirection={"row"}
        justifyContent="space-between"
        alignItems={"center"}
        height={"70px"}
        px={isMobile ? 2 : 0}
        // borderBottom={isMobileDesign ? "none" : common.border}
      >
        <Stack
          style={{ cursor: "pointer" }}
          onClick={() => navigate(PublicPaths.indexPage)}
        >
          <img src={"/logo.png"} alt="heading-logo" height={isMobileDesign ? 32 : 40} />
        </Stack>

        {isMobileDesign ? (
          <IconButton onClick={() => setOpenMobileMenu(!openMobileMenu)} sx={{ px: 0 }}>
            {openMobileMenu ? <CloseIcon /> : <MenuIcon />}
          </IconButton>
        ) : (
          <Stack flexDirection={"row"} gap={2} alignItems="center">
            {/* <SignInOutBtn /> */}
            <Button sx={{ color: "black" }} onClick={handleClickSignInAndSignOut}>
              {isLoggedIn ? "Logout" : "Login"}
            </Button>
          </Stack>
        )}
      </Stack>

      {isMobileDesign && openMobileMenu && (
        <Stack rowGap={2} p={3}>
          {/* <SignInOutBtn /> */}
          <Button sx={{ color: "black" }} onClick={handleClickSignInAndSignOut}>
            {isLoggedIn ? "Logout" : "Login"}
          </Button>
        </Stack>
      )}
    </Stack>
  );
};

export const Footer = () => {
  const {
    palette: { common },
  } = useTheme();
  const { isMobile, isTablet, isDesktop } = useViewport();

  return (
    <>
      <Box
        display={isMobile ? "block" : "flex"}
        justifyContent="space-between"
        py={5}
        px={isDesktop ? 10 : isTablet ? 5 : 2}
        borderTop={common.border}
      >
        <Box
          component="img"
          src={RoundtablesHorizontalMainColor}
          height={32}
          mb={isMobile ? 3 : 0}
        />
        <Grid
          container
          columnSpacing={isDesktop ? 10 : 2}
          rowGap={3}
          justifyContent={isMobile ? "start" : "flex-end"}
        >
          <Grid item xs={11} tablet={6} sm={3}>
            <Stack rowGap={2.5}>
              <Typography fontWeight={700}>ABOUT</Typography>
              <Stack rowGap={1.5}>
                {aboutLinks.map((link, index) => {
                  return (
                    <Typography
                      key={`${index}-${link.label}-about-section`}
                      color={common.doverGrey}
                    >
                      {link.label}
                    </Typography>
                  );
                })}
              </Stack>
            </Stack>
          </Grid>
          <Grid item>
            <Stack rowGap={2.5}>
              <Typography fontWeight={700}>SUPPORT</Typography>
              <Stack rowGap={1.5}>
                {supportLinks.map((link, index) => {
                  return (
                    <Typography
                      key={`${index}-${link.label}-about-section`}
                      color={common.doverGrey}
                    >
                      {link.label}
                    </Typography>
                  );
                })}
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="end"
        py={5}
        px={isDesktop ? 10 : isTablet ? 5 : 2}
        borderTop={common.border}
      >
        <Stack rowGap={3} width={"100%"}>
          <Stack
            flexDirection={isMobile ? "column" : "row"}
            justifyContent={"space-between"}
            gap={3}
          >
            <Button
              startIcon={
                <SvgIcon>
                  <UnitedKingdomIcon />
                </SvgIcon>
              }
              sx={{
                color: "common.black",
                fontWeight: 400,
                width: "fit-content",
                border: common.border,
                borderRadius: 2,
              }}
            >
              English
            </Button>
            <Stack flexDirection={"row"} alignItems={"center"} gap={2}>
              <LinkedInIcon />
              <FacebookIcon />
              <MediumIcon />
              <XIcon />
            </Stack>
          </Stack>
          <Box
            display="flex"
            flexDirection={isMobile ? "column" : "row"}
            columnGap={5}
            rowGap={1}
          >
            <Typography>© {new Date().getFullYear()} Roundtable</Typography>
            <Box
              display="flex"
              flexDirection={isMobile ? "column" : "row"}
              columnGap={2}
              rowGap={1}
            >
              <Typography>Terms of Use</Typography>
              <Typography>Cookie Policy</Typography>
              <Typography>Privacy Notice</Typography>
            </Box>
          </Box>
        </Stack>
        {/* {!isMobile && <IconSystems />} */}
      </Box>
    </>
  );
};
