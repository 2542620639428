
import {
  Container,
  Stack,
  Grid,
  Box,
  CssBaseline
} from "@mui/material";
import Typography from "./components/GatherlanceTypography"

import { Header, Footer } from "./components/HeaderAndFooter";
import HeroSection from "./components/HeroSection";
import Section2 from "./components/Section2";
// import Section3 from "./components/Section3";
import Section4 from "./components/Section4";
import Section5 from "./components/Section5";
import Section6 from "./components/Section6";
import Subscribe from "./components/Subscribe";

import describedGroup from "@assets/images/gatherlance-landing/described_group.png";
import describedGroup2 from "@assets/images/gatherlance-landing/described_group_2.png";

import { useViewport } from "@contexts/ViewportContext";

const GatherlanceLanding = () => {
  const { isDesktop, isMobile } = useViewport();

  return (
    <Stack>
      <CssBaseline />
      <Stack
        sx={{ position: "sticky", top: 0, width: "100%", bgcolor: "white", zIndex: 10 }}
      >
        <Header />
      </Stack>

      <Stack bgcolor={"common.deutziaWhite"} position={"relative"} overflow={"hidden"}>
        <Box
          component={"img"}
          src={describedGroup}
          sx={{ position: "absolute", right: isMobile ? "-50%" : -20 }}
        />
        <Box
          component={"img"}
          src={describedGroup2}
          sx={{ position: "absolute", bottom: isMobile ? "-16%" : 0, left: -4 }}
        />
        <Container fixed={isDesktop}>
          <HeroSection />
        </Container>
      </Stack>
      
      <Container fixed={isDesktop}>
        <Section2 />
      </Container>
      {/* <Stack position={"relative"} overflow={"hidden"}>
        {!isMobile && (
          <Box
            component={"img"}
            src={describedGroup2}
            sx={{ position: "absolute", right: "-5%", height: "50%", top: "19%", zIndex: -1 }}
          />
        )}
        <Container fixed={isDesktop}>
          <Section3 />
        </Container>
      </Stack> */}

      <Stack bgcolor={"common.deutziaWhite"}>
        <Container fixed={isDesktop}>
          <Section4 />
        </Container>
      </Stack>

      <Container fixed={isDesktop}>
        <Section5 />
      </Container>

      <Container fixed={isDesktop}>
        <Section6 />
      </Container>

      <Stack bgcolor={"common.deutziaWhite"}>
        <Container fixed={isDesktop}>
          <Grid container py={{ xs: 8, md: 10 }}>
            <Grid item xs={12} md={7} sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
              <Typography fontWeight={600} fontSize={{ xs: 24, md: 30 }} lineHeight={{ xs: "32px", md: "38px" }}>Be our early user!</Typography>
              <Typography fontSize={{ xs: 16, md: 20 }} lineHeight={{ xs: "24px", md: "30px" }} color={"common.gray6"}>Join waitlist to be our early  seller in beta test! </Typography>
            </Grid>
            <Grid item xs={12} md={5}>
              <Subscribe />
            </Grid>
          </Grid>
        </Container>
      </Stack>

      <Footer />
    </Stack>
  )
}

export default GatherlanceLanding;
