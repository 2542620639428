import { useCallback, useEffect, useState } from "react";

import { GridPaginationModel } from "@mui/x-data-grid";
import { useQuery } from "@tanstack/react-query";

import { axios } from "@features/config";

import { Roundtable } from "@dto/roundtable";

import { RoundtableType } from "@@types/type";

type Response = {
  data: Roundtable[];
  totalItems: number;
};

interface Request extends Partial<GridPaginationModel> {
  title: string;
  type: RoundtableType[];
  userId: string;
  requireVoteWeight?: boolean;
}

const getJoinedRoundtables = async ({
  title,
  type,
  page = 0,
  pageSize = 5,
  userId,
  requireVoteWeight = false,
}: Partial<Request>): Promise<Response> => {
  const response = await axios.get(`/round-tables/joined`, {
    params: {
      title,
      pageSize,
      type: type?.join(","),
      page: page + 1,
      userId,
      requireVoteWeight,
    },
  });

  return response.data.data;
};

const useGetJoinedRoundtables = (initParams: Partial<Request>) => {
  const [params, setParams] = useState(initParams);

  const { data, isLoading, isSuccess, isError, error, refetch, isFetching } = useQuery(
    ["getJoinedRoundtables", params],
    () => getJoinedRoundtables({ ...params })
  );

  useEffect(() => {
    refetch();
  }, [params]);

  const handleChangeParams = useCallback((params: Partial<Request>) => {
    setParams((prev) => ({ ...prev, ...params }));
  }, []);

  return {
    data: data?.data || [],
    totalItems: data?.totalItems || 0,
    isLoading,
    isFetching,
    isSuccess,
    isError,
    error,
    handleChangeParams,
  };
};

export default useGetJoinedRoundtables;
