import { useNavigate } from "react-router-dom";

import { ProtectedPaths } from "@routers/path/ProtectedPath";
import { PublicPaths } from "@routers/path/PublicPath";

type RouteWithParams =
  | typeof PublicPaths.publishedProjectDetail
  | typeof ProtectedPaths.chatRoundTableId
  | typeof PublicPaths.productDetail;
type RouteWithoutParams = string;

type Route = RouteWithParams | RouteWithoutParams;

const useRedirectTo = () => {
  const navigate = useNavigate();

  const replaceParams = (route: Route, values?: string[]): string => {
    if (values) {
      return route.replace(/:\w+/g, () => values.shift() || "");
    } else {
      return route as string;
    }
  };

  function redirectTo(route: RouteWithParams, values: string[]): void;
  function redirectTo(route: RouteWithoutParams): void;

  function redirectTo(route: Route, values?: string[]): void {
    const replacedRoute = replaceParams(route, values);
    navigate(replacedRoute);
  }

  function getRedirectRoute(route: RouteWithParams, values: string[]): string;
  function getRedirectRoute(route: RouteWithoutParams): string;

  function getRedirectRoute(route: Route, values?: string[]): string {
    return replaceParams(route, values);
  }

  return {
    redirectTo,
    getRedirectRoute,
  };
};

export default useRedirectTo;
