import MuiDrawer, { DrawerProps } from "@mui/material/Drawer";
import { styled } from "@mui/material/styles";

import { drawerWidth, openedMixin, closedMixin } from "../styles";

interface CustomDrawerProps extends DrawerProps {
  isDesktop?: boolean;
}
const Drawer = styled(MuiDrawer)<CustomDrawerProps>(({ theme, open, isDesktop }) => ({
  width: drawerWidth,

  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ".MuiPaper-root ": {
    background: "white",
    padding: open ? "16px 0 24px" : "0",
    width: drawerWidth,
  },
  ...(open &&
    isDesktop && {
      ...openedMixin(theme),
      "& .MuiDrawer-paper": openedMixin(theme),
    }),
  ...(!open &&
    isDesktop && {
      ...closedMixin(theme),
      "& .MuiDrawer-paper": closedMixin(theme),
    }),
}));

export default Drawer;
