import { CSSProperties } from "react";

import { Theme, CSSObject, SxProps } from "@mui/material/styles";

export const drawerWidth = 280;

export const DrawerHeaderHeight = 72;
export const DrawerFooterHeight = 64;

export const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  boxSizing: "border-box",
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

export const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

export const iconStyles: CSSProperties = {
  width: 24,
  height: 24,
  color: "#4A5568",
};

export type PageLayoutStylesKey =
  | "drawerHeaderContainer"
  | "logoutButton"
  | "naveItemsContainer";

export const PageLayoutStyles: Record<PageLayoutStylesKey, SxProps> = {
  drawerHeaderContainer: {
    flexGrow: 1,
    width: "100%",
    maxWidth: "100%",
    overflowX: "hidden",
    height: "100vh",
  },
  logoutButton: {
    m: open ? 2 : 1,
    minWidth: open ? 64 : "auto",
    ".MuiButton-startIcon": { mr: open ? 1 : 0 },
  },
  naveItemsContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100%",
    padding: "0 20px",
    flex: 1,
  },
};
