import { getUrls, uploadFile } from "@api/upload";

export const uploadImagesToServer = async (files: File[], endpoint?: string): Promise<string[]> => {
  const fileNames = files.map((f) => f.name);
  const getUrlResponse = await getUrls(fileNames, endpoint);
  if (!getUrlResponse.success) {
    return [];
  }

  const { data } = getUrlResponse;
  const promises = data?.map?.(({ uploadLink }, index) =>
    uploadFile(uploadLink, files[index], {
      headers: {
        "Content-Type": "application/octet-stream",
      },
    })
  );

  try {
    await Promise.all(promises);
    return data?.map((i) => i.imageLink);
  } catch (e) {
    return [];
  }
};

export const validateDropFiles = ({
  files,
  maxFileSize = 15000000,
  acceptedFiles = ["image/jpeg", "image/png", "image/bmp"],
}: {
  files: FileList;
  maxFileSize?: number;
  acceptedFiles?: string[];
}): File[] => {
  return Array.from(files).filter(
    (file) => file.size <= maxFileSize && acceptedFiles.includes(file.type)
  );
};
