import { useCallback, useState } from "react";

import { useQuery } from "@tanstack/react-query";

import { axios } from "@features/config";

import { NOTIFICATION_TYPE, NotificationDto } from "@dto/notification";

type Response = {
  data: NotificationDto[];
};

export type GetNotificationsRequest = {
  type?: NOTIFICATION_TYPE;
  enabled?: boolean;
  refetchInterval?: number;
};

const getNotifications = async (type?: NOTIFICATION_TYPE): Promise<Response> => {
  const params = {
    type,
    isArchived: false,
  };
  if (params.type === NOTIFICATION_TYPE.ARCHIVE) {
    delete params.type;
    params.isArchived = true;
  }
  const response = await axios.get(`/users/notifications`, {
    params,
  });
  return response.data;
};

const useGetNotifications = (initialParams: GetNotificationsRequest) => {
  const [params, setParams] = useState(initialParams);

  const { data, ...rest } = useQuery(
    ["getNotifications", params.type],
    () => getNotifications(params.type),
    {
      enabled: params.enabled,
      refetchInterval: params.refetchInterval,
    }
  );

  const handleChangeParams = useCallback((params: Partial<GetNotificationsRequest>) => {
    setParams((prev) => ({ ...prev, ...params }));
  }, []);

  return {
    data: data?.data || [],
    params,
    handleChangeParams,
    ...rest,
  };
};

export default useGetNotifications;
