import { Stack, Chip } from "@mui/material";
import Typography from "./GatherlanceTypography";
import { Check as CheckIcon } from "@mui/icons-material";

import section2Img from "@assets/images/gatherlance-landing/section2.png";

const Section2 = () => {
  return (
    <Stack flexDirection={{ sm: "column", md: "row" }} flexWrap={"wrap"} gap={{ xs: 4, md: 10 }} py={{ xs: 8, md: 10 }}>
      <Stack
        flex={1}
        width={{ xs: "100%", sm:"100%", md: "60%" }}
        gap={2}
      >
        <Chip label="Gatherlance feature" sx={{ width: "max-content", bgcolor: "#EDF2F7" }} />
        <Typography fontWeight={600} fontSize={{ xs: 30, md: 40 }} lineHeight={{ xs: "38px", md: "44px" }}>Screening and Working with freelancers in a productivity space</Typography>
        <Typography fontSize={{ xs: 18, md: 20 }} color={"common.gray6"}>It feels just like working with your colleagues! Bring your team here also!</Typography>
      </Stack>
      <Stack
        // flex={1}
        width={{ xs: "100%", sm:"100%", md: "40%" }}
        gap={2}
        mt={{ md: 3 }}
      >
        <Stack flexDirection={"row"} gap={2}>
          <Stack
            bgcolor={"common.bellflowerBlue"}
            borderRadius={"50%"}
            minWidth={28} height={28}
            alignItems={"center"} justifyContent={"center"}
          ><CheckIcon sx={{ width: 18, height: 18 }} /></Stack>
          <Typography fontSize={{ xs: 18, md: 20 }} color={"common.gray6"}>{"Post a job and screen freelancer together with your team"}</Typography>
        </Stack>
        <Stack flexDirection={"row"} gap={2}>
          <Stack
            bgcolor={"common.bellflowerBlue"}
            borderRadius={"50%"}
            minWidth={28} height={28}
            alignItems={"center"} justifyContent={"center"}
          ><CheckIcon sx={{ width: 18, height: 18 }} /></Stack>
          <Typography fontSize={{ xs: 18, md: 20 }} color={"common.gray6"}>Utilize different texts and audio channels</Typography>
        </Stack>
        <Stack flexDirection={"row"} gap={2}>
          <Stack
            bgcolor={"common.bellflowerBlue"}
            borderRadius={"50%"}
            minWidth={28} height={28}
            alignItems={"center"} justifyContent={"center"}
          ><CheckIcon sx={{ width: 18, height: 18 }} /></Stack>
          <Typography fontSize={{ xs: 18, md: 20 }} color={"common.gray6"}>Access to productivity feature & tools all in a single page</Typography>
        </Stack>
      </Stack>
      <Stack
        // flex={1}
        width={"100%"}
        borderRadius={"12px"}
        overflow={"hidden"}
        boxShadow={"0px 0px 21.9px 2px rgba(75, 75, 75, 0.10)"}
      >
        <img width={"100%"} src={section2Img} alt="section2" />
      </Stack>
    </Stack>
  )
}

export default Section2;
