import { useQuery } from "@tanstack/react-query";

import { axios } from "@features/config";

export interface SearchCriteriaResponse {
  tags: string[];
  locations: string[];
  institutions: string[];
  categories: {
    name: string;
    tags: string[];
    isDisplay: boolean;
    path: string | null;
  }[];
  countries: string[];
}

const getSearchCriteria = async (): Promise<SearchCriteriaResponse> => {
  const response = await axios.get(`/round-tables/search-criteria`);
  return response.data.data;
};

const useGetSearchCriteria = ({ enabled = true }: { enabled?: boolean } = {}) => {
  const { data, ...rest } = useQuery(["getSearchCriteria"], () => getSearchCriteria(), {
    enabled,
  });

  return {
    data,
    ...rest,
  };
};

export default useGetSearchCriteria;
