import { ReactNode } from "react";

import FacebookIcon from "@mui/icons-material/Facebook";
import LanguageIcon from "@mui/icons-material/Language";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/Twitter";
import { Box, Button, Grid, Stack, Typography, useTheme } from "@mui/material";

import RoundtablesHorizontalMainColor from "@assets/roundtable/PNG/Roundtables/Roundtables-Horizontal-MainColor.png";

import { useViewport } from "@contexts/ViewportContext";

const aboutLinks = [
  { label: "About us", href: "#" },
  { label: "Press center", href: "#" },
  { label: "How Roundtable Works", href: "#" },
];

const supportLinks = [
  { label: "Help Center", href: "#" },
  { label: "Term & Policy", href: "#" },
];

const StyledTypography = ({ children }: { children: ReactNode }) => (
  <Typography fontSize={14} color="common.doverGrey">
    {children}
  </Typography>
);

const IconSystems = () => {
  return (
    <Box display="flex" columnGap={4}>
      <LinkedInIcon />
      <FacebookIcon />
      <TwitterIcon />
    </Box>
  );
};

const LandingFooter = () => {
  const {
    palette: { common },
  } = useTheme();
  const { isMobile, isTablet, isDesktop } = useViewport();

  return (
    <>
      <Box
        display={isMobile ? "block" : "flex"}
        justifyContent="space-between"
        py={5}
        px={isDesktop ? 10 : isTablet ? 5 : 2}
        borderTop={common.border}
      >
        <Box
          component="img"
          src={RoundtablesHorizontalMainColor}
          height={32}
          mb={isMobile ? 3 : 0}
        />
        <Grid
          container
          columnSpacing={isDesktop ? 10 : 2}
          rowGap={3}
          justifyContent={isMobile ? "start" : "flex-end"}
        >
          <Grid item xs={11} tablet={6} sm={3}>
            <Stack rowGap={2.5}>
              <Typography fontWeight={700}>ABOUT</Typography>
              <Stack rowGap={1.5}>
                {aboutLinks.map((link, index) => {
                  return (
                    <Typography
                      key={`${index}-${link.label}-about-section`}
                      color={common.doverGrey}
                    >
                      {link.label}
                    </Typography>
                  );
                })}
              </Stack>
            </Stack>
          </Grid>
          <Grid item>
            <Stack rowGap={2.5}>
              <Typography fontWeight={700}>SUPPORT</Typography>
              <Stack rowGap={1.5}>
                {supportLinks.map((link, index) => {
                  return (
                    <Typography
                      key={`${index}-${link.label}-about-section`}
                      color={common.doverGrey}
                    >
                      {link.label}
                    </Typography>
                  );
                })}
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="end"
        py={5}
        px={isDesktop ? 10 : isTablet ? 5 : 2}
        borderTop={common.border}
      >
        <Stack rowGap={2}>
          <Button
            startIcon={<LanguageIcon />}
            sx={{
              color: "common.black",
              fontWeight: 400,
              width: "fit-content",
              border: common.border,
              borderRadius: 2,
            }}
          >
            English
          </Button>
          {isMobile && <IconSystems />}
          <Box
            display="flex"
            flexDirection={isMobile ? "column" : "row"}
            columnGap={5}
            rowGap={1}
          >
            <StyledTypography>© {new Date().getFullYear()} Roundtable</StyledTypography>
            <Box
              display="flex"
              flexDirection={isMobile ? "column" : "row"}
              columnGap={2}
              rowGap={1}
            >
              <StyledTypography>Terms of Use</StyledTypography>
              <StyledTypography>Cookie Policy</StyledTypography>
              <StyledTypography>Privacy Notice</StyledTypography>
            </Box>
          </Box>
        </Stack>
        {!isMobile && <IconSystems />}
      </Box>
    </>
  );
};

export default LandingFooter;
