import { isProject } from './roundtable.utils'

const returnValueOrDefault = <T, U>(value: T, defaultValue: U): T | U =>
  value || defaultValue;

const value2Percent = (value: number, total: number, fixed = 2) => {
  return ((value / total) * 100).toFixed(fixed);
};

const getAbbreviation = (name: string) => {
  if (!name) {
    return "";
  }

  const parts = name?.split(" ");

  if (parts.length > 1) {
    return `${parts[0].slice(0, 1)}${parts[1].slice(0, 1)}`;
  } else {
    return name.slice(0, 2);
  }
};

const generateHSL = (name: string) => {
  if (!name) {
    return null;
  }

  const hRange = [0, 360];
  const sRange = [25, 50];
  const lMin = 40;
  const lMax = 70;

  const hash = getHashOfString(name);
  const h = normalizeHash(hash, hRange[0], hRange[1]);
  const s = normalizeHash(hash, sRange[0], sRange[1]);
  const l = normalizeHash(hash, lMin, lMax);
  return `hsl(${h}, ${s}%, ${l}%)`;
};

const normalizeHash = (hash: number, min: number, max: number) => {
  return Math.floor((hash % (max - min)) + min);
};

const getHashOfString = (str: string) => {
  const charArray = Array.from(str);
  return charArray.reduce((total, _char, index) => {
    return (total += str.charCodeAt(index) * index);
  }, 0);
};

const capitalizeFirstLetter = (str: string) => {
  const lowercaseStr = str.toLowerCase();
  const words = lowercaseStr.split(" ");

  const capitalizedWords = words.map((word) => {
    const firstLetter = word.charAt(0).toUpperCase();
    const restOfWord = word.slice(1).toLowerCase();
    return `${firstLetter}${restOfWord}`;
  });

  return capitalizedWords.join(" ");
};

/* eslint-disable  @typescript-eslint/no-explicit-any */
const isValidValue = (value: any): boolean =>
  value !== null && value !== undefined && value !== "" && !Number.isNaN(value);

/* eslint-disable  @typescript-eslint/no-explicit-any */
const buildQueryString = (params: Record<string, any>): string => {
  const queryParams: string[] = [];

  for (const [key, value] of Object.entries(params)) {
    if (Array.isArray(value)) {
      value.filter(isValidValue).forEach((item) => {
        queryParams.push(`${encodeURIComponent(key)}=${encodeURIComponent(item)}`);
      });
    } else if (isValidValue(value)) {
      queryParams.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`);
    }
  }

  return queryParams.length > 0 ? `?${queryParams.join("&")}` : "";
};

export {
  isProject,
  returnValueOrDefault,
  value2Percent,
  getAbbreviation,
  generateHSL,
  capitalizeFirstLetter,
  buildQueryString,
};
