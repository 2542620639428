import Axios, { AxiosError } from "axios";

import { signOut } from "@features/auth/slice";

export type ErrorResponse = {
  code: number;
  message: string;
};

export const axios = Axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: true,
});

export const setUpInterceptor = (store: any) => {
  axios.interceptors.request.use(function (config) {
    const idToken = store.getState()?.auth?.userInfo?.idToken;
    config.headers.Authorization = `Bearer ${idToken}`;

    return config;
  });

  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      const status = error.response?.status || 500;
      const url = error.config.url;

      if (status === 403) {
        return (window.location.href = "/");
      }

      if (status === 401 && url !== "/login") {
        store.dispatch(signOut());
        (
          window as Window
        ).location = `${window.location.protocol}//${window.location.host}/sign-in`;
      }
      return Promise.reject(error);
    }
  );
};

export const handleApiError = (error: unknown): { success: boolean; message: string } => {
  const err = error as AxiosError<ErrorResponse>;
  let message;
  if (err.response) {
    const {
      response: { data },
    } = err;
    message = data?.message;
    return { success: false, message: data?.message };
  } else {
    message = err.message;
  }

  return { success: false, message };
};
