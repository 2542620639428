import { configureStore } from "@reduxjs/toolkit";

import authReducer from "@features/auth/slice";
import managementRoundTableReducer from "@features/managementRoundTable/slice";
import managementUsersReducer from "@features/managementUser/slice";
import sidebarReducer from "@features/sidebar/slice";
import usersReducer from "@features/user/slice";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    managementRoundTable: managementRoundTableReducer,
    users: usersReducer,
    managementUsers: managementUsersReducer,
    sideBar: sidebarReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
