import { Box, styled } from "@mui/material";

import { DrawerFooterHeight, DrawerHeaderHeight } from "../styles";

const DrawerBody = styled(Box)<{ sidebarOpen: boolean }>(({ sidebarOpen }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  height: `calc(100% - ${DrawerHeaderHeight}px - ${DrawerFooterHeight}px)`,
  padding: sidebarOpen ? "20px 20px 0" : 0,
  flex: 1,
  overflowY: "auto",
}));

export default DrawerBody;
