export enum NOTIFICATION_TYPE {
  NEW_PROPOSAL = "NEW_PROPOSAL",
  VOTE_DELEGATION = "VOTE_DELEGATION",
  MENTION = "MENTION",
  ARCHIVE = "ARCHIVE",
}

export type NotificationDto = {
  roundTableAvatar?: string;
  userAvatar?: string;
  createdAt: Date;
  id: string;
  isRead: boolean;
  isArchived: boolean;
  name: string;
  title: string;
  roundTableId?: string;
  proposalId?: string;
  type: NOTIFICATION_TYPE;
  parentRoundTableId?: string;
};
