import { useState, ReactNode } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { Typography, Stack } from "@mui/material";
import { AuthPath } from "@routers/path/AuthPath";
import { PublicPaths } from "@routers/path/PublicPath";

import { useAppSelector } from "@app/hooks";

import Button, { IProps as ButtonProps } from "@components/Button";
import CreateProjectLandingDialog, {
  ProjectField,
} from "@components/Dialog/CreateProjectLandingDialog";

// import MultiFileUploader from "@components/MultiFileUploader";
import { addNewUrlParams } from "@utils/helpers/stringHelper";

// const FieldsPerEachStep: Record<any, string[]> = {
//   4: ["invitees"]
// }

interface IProps {
  text?: string;
  style?: "normal" | "transparent";
  btnProps?: ButtonProps & {
    icon?: ReactNode;
  };
  defaultValues?: Pick<ProjectField, "category">;
  fixedCategory?: boolean;
}
const CreateProject = (props: IProps) => {
  const { text, btnProps } = props;
  // const [navItems, { handleChangeExpandSidebarItems }] = useNavItems();
  const [dialogOpen, setDialogOpen] = useState(false);

  // const imageUploaderRef = useRef(null);
  // const documentUploaderRef = useRef(null);
  const location = useLocation();
  const isProductPath = location.pathname === PublicPaths.product;
  const { userInfo } = useAppSelector((state) => state.auth);
  const navigate = useNavigate();

  const handleClose = () => {
    setDialogOpen(null);
  };

  const navigateToSignIn = () => {
    return navigate(
      addNewUrlParams(AuthPath.signIn, {
        redirectTo: PublicPaths.projectPage,
      })
    );
  };

  return (
    <div>
      <Button
        onClick={userInfo ? () => setDialogOpen(true) : () => navigateToSignIn()}
        {...btnProps}
      >
        <Stack display={"flex"} flexDirection={"row"} alignItems={"center"} gap={1}>
          <Typography fontWeight={700} fontSize={14}>
            {text || `Create new ${isProductPath ? "product" : "project"}`}
          </Typography>
          {btnProps?.icon}
        </Stack>
      </Button>

      {dialogOpen && (
        <CreateProjectLandingDialog
          openTutorial={dialogOpen}
          handleClose={handleClose}
          defaultValues={props.defaultValues as any}
          fixedCategory={props.fixedCategory}
        />
      )}
    </div>
  );
};

export default CreateProject;
