import { useAppSelector } from "@app/hooks";

import { UserInfo } from "@features/auth/slice";

import { User } from "@dto/user";

const useGetAccountInfo = (): Partial<UserInfo | { user: Partial<User> }> => {
  const info = useAppSelector((state) => state.auth?.userInfo);

  return info;
};

export default useGetAccountInfo;
