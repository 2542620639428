import React from "react";

import { Typography, TypographyProps } from "@mui/material";

interface IProps extends TypographyProps {
  content?: string;
  numberOfLines?: number | string;
}

export default function EllipsisContent({
  content,
  variant = "h2",
  numberOfLines = 2,
  sx,
  children,
  ...rest
}: IProps) {
  return (
    <>
      <Typography
        sx={{
          display: "-webkit-box",
          WebkitBoxOrient: "vertical",
          overflow: "hidden",
          WebkitLineClamp: numberOfLines,
          textOverflow: "ellipsis",
          ...sx,
        }}
        mt={1}
        variant={variant}
        fontSize={rest.fontSize || 36}
        fontWeight={700}
        lineHeight={rest.lineHeight || "43px"}
        {...rest}
      >
        {content || children}
      </Typography>
    </>
  );
}
