export const ProtectedPaths = {
  dashboard: "/dashboard",
  product: "/product",
  myRoundTables: "/my-roundtables",

  myAccount: "/my-account",

  messenger: "/messenger",
  conversationId: ":conversationId",

  search: "/search",
  roundTable: "Roundtable",
  user: "User",
  project: "Project",

  myProjects: "/my-projects",

  roundTableId: "/:roundtableId",
  chatRoundTableId: "/chat/:roundtableId",
  chatProjectId: ":projectId",

  calendar: "/calendar",

  chat: "/chat",

  currentPath: "/#",
} as const;
