import { axios, handleApiError } from "@features/config";

type Request = { email: string; type: string };
type Response = {
  success: boolean;
  data?: { code: number; message: string; data: boolean };
  message?: string;
};

export const subcrible = async (payload: Request): Promise<Response> => {
  try {
    const { data } = await axios.post(`/wait-list`, {
      ...payload,
      ref: location.pathname,
    });
    return { success: true, data: data?.data };
  } catch (error) {
    return handleApiError(error);
  }
};
