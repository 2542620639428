import { FC } from "react";

import { SxProps, Typography, styled } from "@mui/material";

const StyledSectionLabel = styled("p")(({ theme }) => ({
  color: theme.palette.common.featherSoftBlue,
  fontSize: 14,
  lineHeight: 16.8,
  fontWeight: 700,
  paddingLeft: 12,
  marginBottom: 8,
}));

interface Props {
  text: string;
}

const styles: SxProps = {
  lineHeight: 1,
};

const SectionLabel: FC<Props> = ({ text }) => {
  return <StyledSectionLabel sx={styles}>{text}</StyledSectionLabel>;
};

export default SectionLabel;
