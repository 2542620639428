import { Stack, Chip } from "@mui/material";
import Typography from "./GatherlanceTypography"
import { Check as CheckIcon } from "@mui/icons-material";

import section41mg from "@assets/images/gatherlance-landing/section4_1.png";
import section42Img from "@assets/images/gatherlance-landing/section4_2.png";

const Section4 = () => {
  return (
    <Stack flexDirection={"row"} flexWrap={"wrap"} gap={{ xs: 8, md: 16 }} py={{ xs: 8, md: 10 }}>
      <Stack flexDirection={{ xs: "column", md: "row" }} flexWrap={"wrap"} gap={8} alignItems={"center"} width={"100%"}>
        <Stack
          flex={1}
          width={{ xs: "100%", md: "50%" }}
          minWidth={300}
          gap={2}
        >
          <Chip label="Gatherlance feature" sx={{ width: "max-content", bgcolor: "#EDF2F7" }} />
          <Typography fontWeight={600} fontSize={{ xs: 30, md: 48 }} lineHeight={{ xs: "38px", md: "60px" }}>Gatherlance quest</Typography>
          <Typography fontSize={{ xs: 18, md: 20 }} lineHeight={{ xs: "28px", md: "30px" }} color={"common.gray6"}>We update milestone to quest! Let us complete your ticket list</Typography>
          <Stack flexDirection={"row"} gap={2}>
            <Stack
              bgcolor={"common.bellflowerBlue"}
              borderRadius={"50%"}
              width={28} height={28}
              alignItems={"center"} justifyContent={"center"}
            ><CheckIcon sx={{ width: 18, height: 18 }} /></Stack>
            <Typography fontSize={{ xs: 18, md: 20 }} lineHeight={{ xs: "28px", md: "30px" }} color={"common.gray6"}>Post multiple projects/tasks rapidly</Typography>
          </Stack>
          <Stack flexDirection={"row"} gap={2}>
            <Stack
              bgcolor={"common.bellflowerBlue"}
              borderRadius={"50%"}
              minWidth={28} height={28}
              alignItems={"center"} justifyContent={"center"}
            ><CheckIcon sx={{ width: 18, height: 18 }} /></Stack>
            <Typography fontSize={{ xs: 18, md: 20 }} lineHeight={{ xs: "28px", md: "30px" }} color={"common.gray6"}>Break down the project to tasks and let multiple freelancers work on it</Typography>
          </Stack>
          <Stack flexDirection={"row"} gap={2}>
            <Stack
              bgcolor={"common.bellflowerBlue"}
              borderRadius={"50%"}
              minWidth={28} height={28}
              alignItems={"center"} justifyContent={"center"}
            ><CheckIcon sx={{ width: 18, height: 18 }} /></Stack>
            <Typography fontSize={{ xs: 18, md: 20 }} lineHeight={{ xs: "28px", md: "30px" }} color={"common.gray6"}>Or let freelancer make the breakdown list in their proposal! Pick the seller with the best plan.</Typography>
          </Stack>
          <Stack flexDirection={"row"} gap={2}>
            <Stack
              bgcolor={"common.bellflowerBlue"}
              borderRadius={"50%"}
              minWidth={28} height={28}
              alignItems={"center"} justifyContent={"center"}
            ><CheckIcon sx={{ width: 18, height: 18 }} /></Stack>
            <Typography fontSize={{ xs: 18, md: 20 }} lineHeight={{ xs: "28px", md: "30px" }} color={"common.gray6"}>Get a quick time estimation from trusted freelancer and start work immediately!</Typography>
          </Stack>
        </Stack>
        <Stack
          flex={1}
        >
          <img width={"100%"} src={section41mg} alt="section2" />
        </Stack>
      </Stack>

      <Stack flexDirection={{ xs: "column-reverse", md: "row" }} flexWrap={"wrap"} gap={8} alignItems={"center"} width={"100%"}>
        <Stack
          flex={1}
          width={{ xs: "100%", md: "50%" }}
        >
          <img width={"100%"} src={section42Img} alt="section2" />
        </Stack>
        <Stack
          flex={1}
          width={{ xs: "100%", md: "50%" }}
          minWidth={300}
          gap={2}
        >
          <Chip label="Gatherlance feature" sx={{ width: "max-content", bgcolor: "#EDF2F7" }} />
          <Typography fontWeight={600} fontSize={{ xs: 30, md: 48 }} lineHeight={{ xs: "38px", md: "60px" }}>AI is at your service!</Typography>
          <Typography fontSize={{ xs: 18, md: 20 }} lineHeight={{ xs: "28px", md: "30px" }} color={"common.gray6"}>{`By saying ‘external productivity’, AI is a part of the story.`}</Typography>
          <Typography fontSize={{ xs: 18, md: 20 }} lineHeight={{ xs: "28px", md: "30px" }} color={"common.gray6"}>{`Post making, proposal filter and more.`}</Typography>
          <Typography fontSize={{ xs: 18, md: 20 }} lineHeight={{ xs: "28px", md: "30px" }} color={"common.gray6"}>{`Development in process`}</Typography>
        </Stack>
      </Stack>
    </Stack>
  )
}

export default Section4;
