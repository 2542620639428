import { createContext, FC, ReactNode, useContext, useState } from "react";
import CustomSnackbar, { ISnackbar } from "@components/Snackbar";

interface IToasterContextProps {
  children: ReactNode
}

export const SnackbarContext = createContext(null)

export const useSnackbar = () => useContext(SnackbarContext)

const SnackbarProvider: FC<IToasterContextProps> = ({ children }) => {
  const [snackbar, setSnackbar] = useState<ISnackbar>({ open: false });
  
  return (
    <SnackbarContext.Provider
      value={{
        snackbar,
        setSnackbar,
      }}
    >
      {children}
      <CustomSnackbar onClose={() => setSnackbar({ open: false })} {...snackbar} />
    </SnackbarContext.Provider>
  )
}

export default SnackbarProvider
