import { RoundTableStatusType } from "@constants/index";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";

import { ErrorResponse, axios } from "@features/config";

import { buildQueryString } from "@utils/index";

export interface ManagementRoundTable {
  id: string;
  avatar?: string;
  title: string;
  status: string;
  member: number;
  type: string;
  createdByUser: {
    avatar: string;
    name: string;
  };
  voteMode: string;
  createdAt: string;
}

interface ManagementRoundTableQuery {
  page: number;
  pageSize: number;
  roundTableName?: string;
  status?: RoundTableStatusType;
  creatorIds?: string[];
  order?: "desc" | "asc";
  orderBy?: string;
}

export const getManagementRoundTables = createAsyncThunk(
  "getManagementRoundTables",
  async (
    {
      page = 0,
      pageSize = 10,
      roundTableName,
      status,
      creatorIds,
      order,
      orderBy,
    }: ManagementRoundTableQuery,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await axios.get(
        `/round-tables/management${buildQueryString({
          page: page + 1,
          pageSize,
          roundTableName,
          status,
          creatorIds,
          order,
          orderBy,
        })}`
      );

      return data;
    } catch (error) {
      const err = error as AxiosError<ErrorResponse>;
      if (err.response) {
        const {
          response: { data },
        } = err;

        return rejectWithValue(data?.message);
      }

      return rejectWithValue(err.message);
    }
  }
);
