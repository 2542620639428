import { useMutation } from "@tanstack/react-query";

import { axios } from "@features/config";

type Request = {
  payload: {
    roundtableId: string;
    virtualLike: number;
    virtualMember: number;
  };
};

type Response = {
  success: boolean;
  code?: number;
  message?: string;
};

const updateProjectVirtualValues = async ({
  payload,
}: Request): Promise<Response> => {
  const response = await axios.put(`/roundtable-virtual`, {
    roundtableId: payload.roundtableId,
    value: {
      virtualLike: payload.virtualLike,
      virtualMember: payload.virtualMember,
    }
  });

  return response.data;
};

const useUpdateProjectVirtualValues = () => {
  return useMutation(updateProjectVirtualValues);
};

export default useUpdateProjectVirtualValues;
