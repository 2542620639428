import { PayloadAction, createSlice } from "@reduxjs/toolkit";

type ManagementUserSliceState = {
  open: boolean;
  openRightBar: boolean;
  openMemberToolMng: boolean;
};

const initialState: ManagementUserSliceState = {
  open: false,
  openRightBar: false,
  openMemberToolMng: false,
};

const sidebarSlice = createSlice({
  name: "sidebar",
  initialState,
  reducers: {
    setSidebarOpen: (state, action: PayloadAction<{ open: boolean }>) => {
      state.open = action.payload.open;
    },
    setRightBarOpen: (state, action: PayloadAction<{ open?: boolean }>) => {
      state.openRightBar = action.payload.open ?? !state.openRightBar;
    },
    setMemberToolMngOpen: (state, action: PayloadAction<{ open?: boolean }>) => {
      state.openMemberToolMng = action.payload.open ?? !state.openMemberToolMng;
    },
  },
});

const { reducer, actions: sidebarActions } = sidebarSlice;

export { sidebarActions };

export default reducer;
