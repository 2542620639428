import { useCollectionData, useDocumentData } from "react-firebase-hooks/firestore";

import { CHAT_TYPES } from "@constants/chat";
import { CONVERSATION_COLlECTION, USER_COLlECTION } from "@fb/const";
import { db } from "@fb/db";
import {
  DocumentData,
  collection,
  query,
  where,
  QueryDocumentSnapshot,
  SnapshotOptions,
  FirestoreDataConverter,
  doc,
  orderBy,
} from "firebase/firestore";

import useGetAccountInfo from "@hooks/useGetAccountInfo";

import { ConversationType } from "./type";

const postConverter: FirestoreDataConverter<DocumentData> = {
  toFirestore: (data: ConversationType) => data,
  fromFirestore(
    snapshot: QueryDocumentSnapshot<ConversationType>,
    options: SnapshotOptions
  ): ConversationType {
    const data = snapshot.data(options);
    return {
      id: snapshot.id,
      ...data,
    };
  },
};

export const useConversation = (userId: string) => {
  const q = query(
    collection(db, CONVERSATION_COLlECTION).withConverter(postConverter),
    where("participants", "array-contains-any", [userId]),
    where("type", "==", CHAT_TYPES.P2P),
    orderBy("date", "desc")
  );

  return useCollectionData<ConversationType>(q as any, {
    snapshotListenOptions: { includeMetadataChanges: true },
  });
};

export const useConversationItem = (conversationId: string) => {
  const q = doc(db, CONVERSATION_COLlECTION, conversationId);
  return useDocumentData<ConversationType>(q as any, {
    snapshotListenOptions: { includeMetadataChanges: true },
  });
};

const useGetUnreadConversations = () => {
  const info = useGetAccountInfo();

  const q = query(
    collection(db, USER_COLlECTION),
    where("email", "==", info.user?.email ?? "")
  );

  const [doc] = useCollectionData(q, {
    snapshotListenOptions: { includeMetadataChanges: true },
  });

  if (!doc || !doc[0]) {
    return [];
  }

  return doc[0];
};

export const useGetUnreadRoundTables = (): string[] => {
  const doc = useGetUnreadConversations();

  return doc?.unreadRoundTableIds ?? [];
};

export const useGetUnreadP2PIds = (): string[] => {
  const doc = useGetUnreadConversations();

  return doc?.unreadP2PIds ?? [];
};
