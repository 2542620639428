import { Stack, Box } from "@mui/material";
import Typography from "./GatherlanceTypography"
import { Star as StarIcon } from "@mui/icons-material";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import section5Img from "@assets/images/gatherlance-landing/section5.png";
import DefaultThumbnail from "@assets/images/defaultThumbnail.png";

const data = [
  {
    stars: 5,
    quote: "Literately get addicted with getting things done on Gatherlance! It feels just like work with our own team.",
    author: "— Beta user"
  },
  {
    stars: 5,
    quote: "Literately get addicted with getting things done on Gatherlance! It feels just like work with our own team.",
    author: "— Beta user"
  },
];

const Section5 = () => {  
  return (
    <Stack py={{ xs: 8, md: 10 }}>
      <Stack flexDirection={{ xs: "column", md: "row" }} bgcolor={"black"} borderRadius={4} overflow={"hidden"}>
        <Box
          flex={1}
          width={{ xs: "100%", md: "58%" }}
        >
          <Box
            sx={{
              "& .swiper-pagination-bullet.swiper-pagination-bullet-active": {
                background: "white",
              },
              "& .swiper-pagination-bullet": {
                bgcolor: "common.gray5",
                opacity: 1,
                width: 10,
                height: 10
              },
              "& .swiper-pagination": {
                textAlign: "left",
                px: { xs: 3.5, md: 6.5 }
              }
            }}
          >
            <Swiper
              modules={[Navigation, Pagination]}
              spaceBetween={50}
              slidesPerView={1}
              navigation={false}
              pagination={{ clickable: true }}
              color={"white"}
            >
              {data?.map((quote, index) => {
                return (
                  <SwiperSlide key={`${index}-quote`}>
                    <Stack gap={2.5} py={{ xs: 5, md: 9 }} px={{ xs: 3.5, md: 6.5 }} >
                      <Stack flexDirection={"row"}>{[1,2,3,4,5].map((_, i) => <StarIcon key={i} sx={{ fill: "#FEC84B" }} />)}</Stack>
                      <Typography color={"white"} fontSize={{ xs: 24, md: 36 }} lineHeight={{ xs: "32px", md: "44px" }}>{quote.quote}</Typography>
                      <Typography color={"white"} fontSize={18} lineHeight={"28px"}>{quote.author}</Typography>
                    </Stack>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </Box>
        </Box>
        <Box
          flex={1}
          width={{ xs: "100%", md: "42%" }}
        >
          <Box
            component="img"
            src={section5Img || DefaultThumbnail}
            sx={{
              width: "100%",
              // aspectRatio: "16/9",
              objectFit: "cover",
            }}
          />
        </Box>
      </Stack>
    </Stack>
  )
}

export default Section5;
