
import { useQuery } from "@tanstack/react-query";

import { axios } from "@features/config";

const useGetUISetting = <PublishProjectResp>() => {

  const { data, ...rest } = useQuery(["getUISetting"], () =>
    axios.get(`/ui-setting/front-page`)
  );

  return {
    data: data?.data?.data || {},
    ...rest,
  };
};

export default useGetUISetting;
