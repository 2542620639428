import { useCallback } from "react";

import { Close } from "@mui/icons-material";
import {
  Alert,
  AlertProps,
  IconButton,
  Snackbar,
  SnackbarCloseReason,
} from "@mui/material";

export interface IProps extends AlertProps {
  closeOnClickAway?: boolean;
  message?: React.ReactNode;
  onClose: () => void;
  open: boolean;
  hideClose?: boolean;
}

export type ISnackbar = Omit<IProps, "onClose">;

export default function CustomSnackbar(props: IProps) {
  const { closeOnClickAway, message, onClose, open, hideClose, ...other } = props;

  const handleClose = useCallback(
    (_event?: React.SyntheticEvent<any> | Event, reason?: SnackbarCloseReason) => {
      if (!closeOnClickAway && reason === "clickaway") {
        return;
      }

      if (onClose) {
        return onClose();
      }
    },
    [closeOnClickAway, onClose]
  );

  return (
    <Snackbar
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      autoHideDuration={6000}
      disableWindowBlurListener
      key={"bottom" + "center"}
      onClose={handleClose}
      open={open}
    >
      {message ? (
        <Alert
          action={
            !hideClose ? (
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose}
              >
                <Close fontSize="small" />
              </IconButton>
            ) : null
          }
          variant="filled"
          {...other}
        >
          {message}
        </Alert>
      ) : null}
    </Snackbar>
  );
}
