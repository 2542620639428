/* eslint-disable indent */
import { FC } from "react";

import { ChevronRight as ChevronRightIcon, Close as CloseIcon } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
// import { PublicPaths } from "@routers/path/PublicPath";

import DefaultThumbnail from "@assets/images/defaultThumbnail.png";

import { PublishedProject } from "@dto/publishedProject";

interface IProps {
  open?: boolean;
  onClose: () => void;
  popularProjects?: PublishedProject[];
}

const PopularProjectPositionsDialog: FC<IProps> = ({
  open = false,
  onClose,
  popularProjects,
}) => {
  const {
    palette: { common },
  } = useTheme();

  const firstProject = popularProjects?.slice(0, 1)[0] as Partial<PublishedProject>;
  const firstThreeProjects = popularProjects?.slice(1, 4);

  return (
    <>
      <Dialog
        fullWidth
        open={open}
        maxWidth="lg"
        onClose={onClose}
        sx={{
          "& .MuiDialog-paper": {
            width: "100%",
            maxWidth: 880,
            m: 1,
          },
        }}
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{ display: "flex", justifyContent: "space-between" }}
        >
          <Typography component="h4" sx={{ fontSize: 20, fontWeight: 700 }}>
            Popular project positions
          </Typography>

          <CloseIcon
            sx={{
              width: 24,
              height: 24,
              color: "common.black",
              cursor: "pointer",
            }}
            onClick={onClose}
          />
        </DialogTitle>
        <DialogContent sx={{ backgroundColor: "common.white" }}>
          <Grid container columnSpacing={5} rowSpacing={2} px={3} py={5}>
            <Grid xs={12}>
              <Stack direction="row" justifyContent="end">
                {firstProject ? (
                  <Box
                    component="img"
                    src={
                      firstProject?.publishData?.thumbnail ||
                      firstProject?.publishData?.thumbnails[0] ||
                      DefaultThumbnail
                    }
                    sx={{
                      width: "100%",
                    
                      // height: 300,
                      aspectRatio: "16/9",
                      borderRadius: 3,
                      objectFit: "cover",
                    }}
                  />
                ) : (
                  <Box
                    sx={{
                      width: "100%",
                      height: 386,
                      aspectRatio: "16/9",
                      borderRadius: 3,
                      bgcolor: "#EDF2F7",
                      border: "1px solid #CBD5E0",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography fontSize={14} fontWeight={600}>TOP</Typography>
                  </Box>
                )}

              </Stack>
            </Grid>
            <Grid
              xs={12}
              // lg={6}
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              gap={2}
            >
              <Stack gap={1.5} flexDirection={'row'}>
                {!firstThreeProjects && [1, 2, 3]?.map((num, idx) => (
                  <Box
                    key={idx}
                    sx={{
                      width: "100%",
                      height: 200,
                      borderRadius: 3,
                      bgcolor: "#EDF2F7",
                      border: "1px solid #CBD5E0",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography fontSize={14} fontWeight={600}>BOTTOM {num}</Typography>
                  </Box>
                ))}
                {firstThreeProjects?.map((project, index) => {
                  return (
                    <Box
                      key={`${index}-popular-project`}
                      component="img"
                      src={
                        project.publishData.thumbnail ||
                        project.publishData.thumbnails[0] ||
                        DefaultThumbnail
                      }
                      sx={{
                        width: "100%",
                        height: 88,
                        borderRadius: 3,
                        objectFit: "cover",
                      }}
                    />
                  );
                })}
              </Stack>
              <Button
                color="inherit"
                variant="outlined"
                endIcon={<ChevronRightIcon />}
                sx={{ mt: 4, width: "100%", textTransform: "unset", border: common.border }}
              >
                Discover more projects
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default PopularProjectPositionsDialog;
