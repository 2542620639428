import { useSearchParams } from "react-router-dom";

import useGetPublishedProjects from "@api/roundtable/useGetPublishedProjects";
import useGetSearchCriteria from "@api/roundtable/useGetSearchCriteria";
import { Category_List } from "@constants/category";
import { Button, Container, Stack, Typography } from "@mui/material";

import LoadingPage from "@pages/PublishedProjects/LoadingPage";

import { useViewport } from "@contexts/ViewportContext";

import PublishedProject from "./components/PublishedProject";
import PublishedProjectsFilter from "./components/PublishedProjectsFilter";

const PublishedProjects = () => {
  const { isDesktop } = useViewport();
  const [searchParams] = useSearchParams();

  const {
    data: projects,
    isLoading,
    params,
    handleChangeParams,
    fetchNextPage,
    hasNextPage,
  } = useGetPublishedProjects({
    page: 0,
    pageSize: 10,
    orderByTime: "DESC",
    title: searchParams.get("keyword") || undefined,
    category: searchParams.get("category") ? [searchParams.get("category")] : [],
    excludeCategory: searchParams.get("category")
      ? undefined
      : Category_List.ComputerProduct,
  });

  const { data: searchCriteria } = useGetSearchCriteria();

  return (
    <Container>
      <Stack rowGap={isLoading ? 0 : isDesktop ? 5 : 0} mb={5}>
        <PublishedProjectsFilter
          params={params}
          handleChangeParams={handleChangeParams}
          searchCriteria={searchCriteria}
          defaultCategory={
            searchParams.get("category") ? [searchParams.get("category")] : []
          }
        />
        {isLoading ? (
          <LoadingPage />
        ) : (
          <>
            <Stack rowGap={isDesktop ? 4 : 2.5}>
              {!!projects.length ? (
                projects.map((project: any, index: number) => {
                  return (
                    <PublishedProject
                      key={`${index}-${project.id}-published-project`}
                      project={project}
                    />
                  );
                })
              ) : (
                <Typography fontWeight={700} margin="0 auto">
                  No results
                </Typography>
              )}
            </Stack>

            {hasNextPage && (
              <Button
                variant="contained"
                onClick={() => fetchNextPage()}
                sx={{ width: 120, margin: isDesktop ? "0 auto" : "16px auto 0" }}
              >
                Show more
              </Button>
            )}
          </>
        )}
      </Stack>
    </Container>
  );
};

export default PublishedProjects;
