import { FC } from "react";

import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import { Box, BoxProps, styled } from "@mui/material";

const Container = styled(Box)(() => ({
  position: "absolute",
  right: -24,
  transform: "translateX(-50%)",
  top: 40,
  padding: 0,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  boxShadow: "0px 2px 4px 0px #0000001A",
  background: "white",
  width: 24,
  aspectRatio: 1,
  cursor: "pointer",
  zIndex: 1201,
}));

const CloseDrawerButton: FC<BoxProps> = (props) => {
  return (
    <Container {...props}>
      <KeyboardDoubleArrowLeftIcon sx={{ color: "common.black" }} />
    </Container>
  );
};

export default CloseDrawerButton;
