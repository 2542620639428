import { FC, ReactNode } from "react";
import { AiOutlineCompass } from "react-icons/ai";

import { PublishDataInformationEnum } from "@api/roundtable/useGetPublishProject";
import { Category_List } from "@constants/category";
import { DATE_WITH_SLASH, NOT_APPLICABLE } from "@constants/index";
import FactoryIcon from "@mui/icons-material/Factory";
import GroupsIcon from "@mui/icons-material/Groups";
import LaunchIcon from "@mui/icons-material/Launch";
import PlaceIcon from "@mui/icons-material/Place";
import ShareIcon from "@mui/icons-material/Share";
import {
  Avatar,
  Box,
  Button,
  Chip,
  Grid,
  IconButton,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import dayjs from "dayjs";

import useRedirectTo from "@hooks/useRedirectTo";

import CustomImages from "@components/CustomImages";
import EllipsisContent from "@components/EllipsisContent";
import ReactionButton from "@components/Reaction";
import ReadMoreText from "@components/ReadMoreText.tsx";

import { copyStrToClipboard } from "@utils/helpers/stringHelper";
import { getYouTubeThumbnailsVideo } from "@utils/helpers/urlHelper";

import DefaultThumbnail from "@assets/images/defaultThumbnail.png";

import { PublishedProject as PublishedProjectDto } from "@dto/publishedProject";

import { useSnackbar } from "@contexts/SnackbarContext";
import { useViewport } from "@contexts/ViewportContext";

interface PublishedProjectProps {
  project: PublishedProjectDto;
}

const FilterField = ({
  label,
  value,
  icon,
}: {
  label: string;
  value: string;
  icon: ReactNode;
}) => {
  const {
    palette: { common },
  } = useTheme();

  return (
    <Grid item xs={12} tablet={5} md={3}>
      <Box
        display="flex"
        alignItems="center"
        gap={1}
        sx={{
          "svg": {
            width: 18,
            height: 18,
          },
        }}
      >
        {icon}
        <Typography fontSize={12} fontWeight={700} color={common.doverGrey}>
          {label}
        </Typography>
        <Typography fontSize={14} fontWeight={400}>
          {value || NOT_APPLICABLE}
        </Typography>
      </Box>
    </Grid>
  );
};

const PublishedProject: FC<PublishedProjectProps> = ({ project }) => {
  const { isDesktop } = useViewport();
  const {
    palette: { primary, common },
  } = useTheme();
  const { getRedirectRoute, redirectTo } = useRedirectTo();
  const { setSnackbar } = useSnackbar();
  const mixedImagesVideos = [
    ...(project?.publishData?.videoLinks || []),
    ...(project?.publishData?.thumbnails || []),
  ];
  const videoThumbnailUrls =
    project?.publishData?.videoLinks
      ?.map((video) => getYouTubeThumbnailsVideo(video.url))
      .filter(Boolean) || [];
  const publishedInfoFields = project?.publishData?.information || [];

  const handleRedirectToProjectDetail = () => {
    if (project.category === Category_List.ComputerProduct) {
      redirectTo("/product/:projectId", [project.shortId]);
    } else {
      redirectTo("/published-projects/:projectId", [project.shortId]);
    }
  };

  return (
    <Stack border={common.border} p={isDesktop ? 3 : 2} rowGap={2}>
      <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
        <EllipsisContent
          sx={{
            cursor: "pointer",
            maxWidth: "100%",
            overflow: "hidden",
            flex: 1,
            mt: 0,
          }}
          fontSize={24}
          lineHeight={"30px"}
          numberOfLines={2}
          onClick={handleRedirectToProjectDetail}
          content={project.title}
        />
        {isDesktop && (
          <Grid
            item
            tablet={2}
            md={3}
            display="flex"
            justifyContent="end"
            alignItems={"center"}
            gap={2}
          >
            {/* <Typography
              variant="subtitle2"
              color={common.gray5}
              sx={{
                fontWeight: 400,
                fontSize: 12,
              }}
            >
              Started: {dayjs(project?.createdAt).format(DATE_WITH_SLASH)}
            </Typography> */}
            <IconButton
              sx={{
                width: isDesktop ? 40 : 32,
                height: isDesktop ? 40 : 32,
              }}
              onClick={() => {
                const url = `${window.location.origin}${getRedirectRoute(
                  "/published-projects/:projectId",
                  [project.shortId]
                )}`;

                copyStrToClipboard(url, setSnackbar);
              }}
            >
              <Tooltip title="Copy link" placement="top" arrow>
                <ShareIcon
                  fontSize="medium"
                  sx={{
                    color: common.gray4,
                    cursor: "pointer",
                  }}
                />
              </Tooltip>
            </IconButton>
            <Box display="flex" alignItems="center" gap={1} color={common.doverGrey}>
              <GroupsIcon />
              <Typography fontSize={12} fontWeight={400}>
                {project.totalMember || 1}
              </Typography>
            </Box>
            <ReactionButton
              projectId={project?.id}
              likeNumber={project?.like}
              liked={project?.liked}
            />

            <Button
              onClick={handleRedirectToProjectDetail}
              variant="contained"
              size="small"
              startIcon={<AiOutlineCompass />}
              sx={{
                minWidth: 120,
                fontWeight: 700,
                height: isDesktop ? 40 : 32,
                fontSize: isDesktop ? 12 : 10,
              }}
            >
              View Details
            </Button>
          </Grid>
        )}
      </Box>
      <Box display="flex" alignItems="center" gap={1}>
        <Avatar
          alt={`${project.roundTableName}-avatar`}
          src={project?.avatar || DefaultThumbnail}
          sx={{ width: 24, height: 24 }}
        />
        {/* <Typography
          sx={{
            fontWeight: 600,
            fontSize: 14,
            color: primary.main,
            overflow: "hidden",
            textOverflow: "unset",
            display: "-webkit-box",
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: 1,
            WebkitBoxLineClamp: 1,
          }}
        >
          {project.roundTableName || NOT_APPLICABLE}
        </Typography> */}
        <EllipsisContent
          color="primary"
          fontSize={14}
          fontWeight={600}
          lineHeight={1.5}
          numberOfLines={"unset"}
          sx={{ mt: 0, wordBreak: "break-word" }}
          content={project.roundTableName || NOT_APPLICABLE}
        />
      </Box>
      <Grid container display="flex" alignItems="center" gap={2}>
        {publishedInfoFields?.includes(PublishDataInformationEnum.LANGUAGE) && (
          <FilterField label="Language" value={project.language} icon={<GroupsIcon />} />
        )}
        {publishedInfoFields?.includes(PublishDataInformationEnum.LOCATION) && (
          <FilterField label="Location" value={project.location} icon={<PlaceIcon />} />
        )}
        {publishedInfoFields?.includes(PublishDataInformationEnum.INSTITUTION) && (
          <FilterField
            label="Institution"
            value={project.institution}
            icon={<FactoryIcon />}
          />
        )}
      </Grid>
      {(!!project.tags || !!project.category) && (
        <Box
          display="flex"
          alignItems="center"
          width="fit-content"
          gap={1}
          flexWrap={"wrap"}
        >
          {project.category && (
            <Chip
              label={project.category}
              variant="filled"
              sx={{
                bgcolor: primary.main,
                color: common.white,
                height: 18,
                fontSize: 12,
                width: "fit-content",
              }}
            />
          )}
          {project.tags.map((tag, index) => {
            return (
              <Chip
                key={`${index}-${tag}-tag`}
                label={tag}
                variant="filled"
                sx={{
                  bgcolor: "#DB00110A",
                  color: primary.main,
                  height: 18,
                  fontSize: 12,
                  width: "fit-content",
                }}
              />
            );
          })}
        </Box>
      )}
      {!isDesktop && (
        <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
          {/* <Typography
            variant="subtitle2"
            color={common.gray5}
            sx={{
              fontWeight: 400,
              fontSize: 12,
            }}
          >
            Started: {dayjs(project?.createdAt).format(DATE_WITH_SLASH)}
          </Typography> */}
          <Box gap={1} display={"flex"}>
            <IconButton
              sx={{
                width: isDesktop ? 40 : 32,
                height: isDesktop ? 40 : 32,
              }}
              onClick={() => {
                const url = `${window.location.origin}${getRedirectRoute(
                  "/published-projects/:projectId",
                  [project.shortId]
                )}`;

                copyStrToClipboard(url, setSnackbar);
              }}
            >
              <Tooltip title="Copy link" placement="top" arrow>
                <ShareIcon
                  fontSize="medium"
                  sx={{
                    color: common.gray4,
                    cursor: "pointer",
                  }}
                />
              </Tooltip>
            </IconButton>
            <Box display="flex" alignItems="center" gap={1} color={common.doverGrey}>
              <GroupsIcon />
              <Typography fontSize={12} fontWeight={400}>
                {project.totalMember || 1}
              </Typography>
            </Box>
            <ReactionButton
              projectId={project?.id}
              likeNumber={project?.like}
              liked={project?.liked}
            />
          </Box>
        </Stack>
      )}
      {publishedInfoFields.includes(PublishDataInformationEnum.DESCRIPTION) && (
        <Typography color={common.doverGrey} fontSize={14} fontWeight={400}>
          <ReadMoreText
            text={project.description || NOT_APPLICABLE}
            fontSize="14px"
            maxLength={isDesktop ? 500 : 260}
          />
        </Typography>
      )}
      <Grid display="flex" flexDirection={"row"} gap={1}>
        {!!mixedImagesVideos.length && (
          <CustomImages
            limitImages={5}
            imageUrls={[
              ...videoThumbnailUrls,
              ...(project?.publishData?.thumbnails || []),
            ]}
            imageSxProps={{
              aspectRatio: 5 / 3,
            }}
            imageGridItem={{ xs: 5.8, tablet: 2.3 }}
            handleClickOnMoreImage={handleRedirectToProjectDetail}
          />
        )}
      </Grid>

      {!isDesktop && (
        <Button
          startIcon={<LaunchIcon />}
          variant="contained"
          sx={{ height: 32, fontSize: 12 }}
          onClick={handleRedirectToProjectDetail}
        >
          View detail
        </Button>
      )}
    </Stack>
  );
};

export default PublishedProject;
