import { useMutation } from "@tanstack/react-query";

import { axios } from "@features/config";

type Request = {
  payload: any;
};

type Response = {
  success: boolean;
  data?: any;
  message?: string;
};

const updateUISetting = async ({
  payload,
}: Request): Promise<Response> => {
  const response = await axios.put(`/ui-setting/front-page`, {
    ...payload,
  });

  return response.data;
};

const useUISetting = () => {
  return useMutation(updateUISetting);
};

export default useUISetting;
