import { createSlice } from "@reduxjs/toolkit";

import { User, getUsers } from "./actions";

type UserSliceState = {
  loading: boolean;
  error: string | null;
  success: boolean;
  users: User[];
  totalItems: number;
  hasMore: boolean;
};

const initialState: UserSliceState = {
  loading: false,
  error: null,
  success: false,
  users: [],
  totalItems: 0,
  hasMore: true,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getUsers.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(getUsers.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;

      if (!state.users.length) {
        state.users = payload?.data?.data || [];
      } else if (state.hasMore) {
        state.users = [...state.users, ...(payload?.data?.data || [])];
      }

      state.totalItems = payload?.data?.totalItems;
      state.hasMore = state.users.length < state.totalItems;
    });

    builder.addCase(getUsers.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload as string;
    });
  },
});

const { reducer } = userSlice;
export default reducer;
