import { useForm } from "react-hook-form";

import { subcrible } from "@api/public/subcrible";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, TextField, FormHelperText, FormControl } from "@mui/material";
import Button from "@components/Button";
import { useMutation } from "@tanstack/react-query";
import * as yup from "yup";

import { useSnackbar } from "@contexts/SnackbarContext";

const formSchema = yup.object().shape({
  email: yup.string().email("Must be a valid email").required("Email is required."),
});

const _DEFAULT_VALUE_ = {
  email: "",
};

export const Subcrible = () => {
  const { setSnackbar } = useSnackbar();
  const form = useForm({
    defaultValues: _DEFAULT_VALUE_,
    resolver: yupResolver(formSchema),
  });
  const mutate = useMutation({
    mutationFn: subcrible,
    onSuccess: () => {
      form.reset(_DEFAULT_VALUE_);
      setSnackbar({
        message: "Thank you! You email has been submitted!",
        open: true,
        severity: "success",
      });
    },
  });
  const onSubmit = form.handleSubmit((data) => {
    mutate.mutate(data);
  });
  return (
    <Box
      component="form"
      onSubmit={onSubmit}
      display="flex"
      // maxWidth={"70%"}
      flexDirection="column"
      id="close-beta"
      gap={1.5}
    >
      <TextField
        sx={{
          backgroundColor: "white",
          "fieldset": {
            borderColor: "common.bellflowerBlue",
            borderRadius: "10px",
          },
          borderRadius: "10px",
          height: "56px",
        }}
        placeholder="Enter your email"
        {...form.register("email", {
          required: true,
        })}
        error={!!form.formState.errors.email}
      />
      {!!form.formState.errors.email && (
        <FormControl error={!!form.formState.errors.email} variant="standard">
          <FormHelperText id="component-error-text">
            {form.formState.errors.email.message}
          </FormHelperText>
        </FormControl>
      )}
      <Button
        disabled={mutate.isLoading}
        loading={mutate.isLoading}
        type="submit"
        variant="contained"
        size="large"
        sx={{
          borderRadius: "10px",
          ":disabled": {
            opacity: 0.7,
            background: "#CC26301A",
            color: "white",
            cursor: "not-allowed",
          },
          textTransform: "unset"
        }}
      >
        Sign up for early access
      </Button>
    </Box>
  );
};
