import { GENDER } from "@constants/index";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";

import { ErrorResponse, axios } from "@features/config";

import { buildQueryString } from "@utils/index";

export interface ManagementUser {
  id: string;
  avatar?: string;
  name: string;
  email: string;
  gender: keyof typeof GENDER;
  level: string;
  dateOfBirth: number;
  phoneNumber: string;
  location: string;
  workingSchedule: string;
  createdAt: string;
}

interface ManagementUserQuery {
  page: number;
  pageSize: number;
  name?: string;
  status?: string;
  level?: string[];
  order?: "desc" | "asc";
  orderBy?: string;
}

export const getManagementUsers = createAsyncThunk(
  "getManagementUsers",
  async (
    { page = 0, pageSize = 10, name, status, level, order, orderBy }: ManagementUserQuery,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await axios.get(
        `/users/management${buildQueryString({
          page: page + 1,
          pageSize,
          order,
          orderBy,
          name,
          status,
          level,
        })}`
      );

      return data;
    } catch (error) {
      const err = error as AxiosError<ErrorResponse>;
      if (err.response) {
        const {
          response: { data },
        } = err;

        return rejectWithValue(data?.message);
      }

      return rejectWithValue(err.message);
    }
  }
);
