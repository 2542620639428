import { GENDER } from "@constants/index";

import { RTFile } from "./file";

export enum Gender {
  NA = "N/A",
  MALE = "MALE",
  FEMALE = "FEMALE"
}

export enum UserRole {
  ADMIN = "ADMIN",
  USER = "USER"
}

export type User = {
  id: string;
  username: string;
  email: string;
  name: string;
  skillTag: string;
  level: string;
  location: string;
  workingSchedule: string;
  role: UserRole;
  avatar: string;
  phoneNumber: string;
  gender: Gender;
  dateOfBirth: string;
  files?: RTFile[];
  createdAt?: string;
  bio?: string;
  experiences?: any[];
  hourRateFrom?: number;
  hourRateTo?: number;
  link?: any[];
  userLanguages?: any[];
  profileProjects?: any[];
  // for invitation
  isLeave?: boolean;
  voteWeight?: number;
  institution?: string;
  description?: string;
};

export type UserManagement = {
  id: string;
  avatar?: string;
  name: string;
  email: string;
  gender: keyof typeof GENDER;
  level: string;
  dateOfBirth: number;
  phoneNumber: string;
  location: string;
  workingSchedule: string;
  createdAt: string;
};
