import React, { FC, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

import { Category_List } from "@constants/category";
import CloseFullscreenIcon from "@mui/icons-material/CloseFullscreen";
import SearchIcon from "@mui/icons-material/Search";
import { Box, IconButton, TextField, useTheme } from "@mui/material";
import { PublicPaths } from "@routers/path/PublicPath";

import { PublishedProject } from "@dto/publishedProject";

import { useViewport } from "@contexts/ViewportContext";

interface HeadingSearchBarProps {
  publishedProjects?: PublishedProject[];
  onClose: () => void;
  fullWidth?: boolean;
}

const HeadingSearchBar: FC<HeadingSearchBarProps> = ({
  onClose,
  // publishedProjects,
  fullWidth,
}) => {
  const {
    palette: { common },
  } = useTheme();
  const { isMobile } = useViewport();
  const navigate = useNavigate();
  const searchingRef = useRef(null);

  const handleSearchBarKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    const searchingValue = searchingRef.current?.value?.trim() || "";
    if (e.key === "Enter" && searchingValue) {
      const category = location.pathname.includes(PublicPaths.product)
        ? Category_List.ComputerProduct
        : null;
      navigate(
        `${PublicPaths.publishedProjects}?keyword=${searchingValue}${
          category ? `&category=${category}&isFixedCategory=true` : ""
        }`
      );
      onClose();
    }
  };
  useEffect(() => {
    searchingRef.current.focus();
  }, []);

  return (
    <Box display="flex" alignItems="center" gap={0.5}>
      {/* <Autocomplete
        options={publishedProjects || []}
        sx={{
          width: fullWidth ? "100%" : "30vw",
          "& .MuiInputBase-input": {
            height: 10,
          },
          label: {
            top: "-4px",
          },
        }}
        getOptionLabel={(option) => option.title}
        onChange={(
          _: React.SyntheticEvent<Element, Event>,
          selectedPublishedProject: PublishedProject
        ) => {
          if (!selectedPublishedProject) {
            return;
          }

          onClose();
          redirectTo(PublicPaths.publishedProjectDetail, [selectedPublishedProject.id]);
        }}
        onKeyDown={(event) => handleSearchBarKeyDown(event)}
        renderOption={(props, option) => (
          <Box component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
            {option.title}
          </Box>
        )}
        renderInput={(params) => (
          <TextField
            inputRef={searchingRef}
            {...params}
            label="Search"
            inputProps={{
              ...params.inputProps,
            }}
          />
        )}
      /> */}
      <TextField
        inputRef={searchingRef}
        onKeyDown={(event) => handleSearchBarKeyDown(event)}
        sx={{
          width: fullWidth ? "100%" : "30vw",
          "& .MuiInputBase-input": {
            height: 10,
          },
          label: {
            top: "-4px",
          },
          "& .MuiInputBase-root": { color: common.doverGrey },
        }}
        InputProps={{
          disableUnderline: true,
          startAdornment: <SearchIcon sx={{ mr: 1 }} />,
        }}
        variant={isMobile ? "standard" : "outlined"}
        placeholder="Search"
      />
      {!isMobile && (
        <IconButton onClick={onClose}>
          <CloseFullscreenIcon />
        </IconButton>
      )}
    </Box>
  );
};

export default HeadingSearchBar;
