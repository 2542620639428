import { useForm } from "react-hook-form";

import { subcrible } from "@api/public/subcrible";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Stack, TextField, FormControlLabel, RadioGroup, Radio, SvgIcon, Icon } from "@mui/material";
import Button from "@components/Button";
import Typography from "./GatherlanceTypography";
import { ReactComponent as BriefcaseIcon } from "@assets/images/gatherlance-landing/briefcase.svg";
import { ReactComponent as UserOctagonIcon } from "@assets/images/gatherlance-landing/user-octagon.svg";

import { useMutation } from "@tanstack/react-query";
import * as yup from "yup";

import { useSnackbar } from "@contexts/SnackbarContext";

const formSchema = yup.object().shape({
  email: yup.string().email("Must be a valid email").required("Email is required."),
  type: yup.string().optional()
});

const _DEFAULT_VALUE_ = {
  email: "",
  type: "CLIENT"
};

interface IProps {
  style?: 'big' | 'minimize';
  buttonText?: string
}

const Subscribe = ({ style = 'minimize', buttonText = 'Subscribe' }: IProps) => {
  const { setSnackbar } = useSnackbar();
  const form = useForm({
    defaultValues: _DEFAULT_VALUE_,
    resolver: yupResolver(formSchema),
  });
  const mutate = useMutation({
    mutationFn: subcrible,
    onSuccess: () => {
      setSnackbar({
        message: "Thank you! You email has been submitted!",
        open: true,
        severity: "success",
      });
      form.reset(_DEFAULT_VALUE_);
    },
    onError: () => {
      setSnackbar({
        message: "Create project error.",
        open: true,
        severity: "error",
      });
    }
  });
  const onSubmit = form.handleSubmit((data) => {
    console.log('data', data)
    mutate.mutate(data);
  });

  const watchRoleType = form.watch("type");

  if (style === 'big') {
    return (
      <Box
      component="form"
      onSubmit={onSubmit}
      display="flex"
      flexDirection="column"
      id="close-beta"
      gap={2}
    >
      <Stack flexDirection={"row"}>
        <RadioGroup
          defaultValue={_DEFAULT_VALUE_.type}
          name="radio-buttons-group"
          value={watchRoleType}
          onChange={(e) => form.setValue("type", e.target.value)}
          sx={{ width: "100%", gap: 2, flexDirection: { xs: "column", md: "row" } }}
        >
          <FormControlLabel
            labelPlacement="start" value="CLIENT"
            control={<Radio />}
            label={
              <Stack flexDirection={"row"} gap={1}>
                <SvgIcon><BriefcaseIcon /></SvgIcon>
                <Typography gap={2}>I’m a client</Typography>
              </Stack>
            }
            sx={{
              flex: 1,
              mx: 0,
              justifyContent: "space-between",
              border: "1px solid #E2E8F0",
              borderRadius: 2,
              bgcolor: "white",
              // height: 75,
              pl: 2, pr: 1, py: 2
            }}
          />
          <FormControlLabel
            labelPlacement="start" value="FREELANCER"
            control={<Radio />}
            label={
              <Stack flexDirection={"row"} gap={1}>
                <SvgIcon><UserOctagonIcon /></SvgIcon>
                <Typography gap={2}>I’m a freelancer</Typography>
              </Stack>
            }
            sx={{
              flex: 1,
              mx: 0,
              justifyContent: "space-between",
              border: "1px solid #E2E8F0",
              borderRadius: 2,
              bgcolor: "white",
              // height: 75,
              pl: 2, pr: 1, py: 2
            }}
          />
        </RadioGroup>
      </Stack>
      <TextField
        sx={{
          backgroundColor: "white",
          "fieldset": {
            borderColor: "common.bellflowerBlue",
            borderRadius: "10px",
          },
          borderRadius: "10px",
          height: "56px",
          flex: 1
        }}
        placeholder="Enter your email"
        {...form.register("email", {
          required: true,
        })}
        error={!!form.formState.errors.email}
        helperText={form.formState?.errors?.email?.message}
      />
      <Button
        disabled={mutate.isLoading}
        loading={mutate.isLoading}
        type="submit"
        variant="contained"
        // size="medium"
        sx={{
          height: 56,
          borderRadius: "10px",
          backgroundColor: "black",
          textTransform: "unset"
        }}
      >
        {buttonText}
      </Button>
    </Box>
    )
  }

  return (
    <Box
      component="form"
      onSubmit={onSubmit}
      display="flex"
      flexDirection={{ xs: "column-reverse", md: "column" }}
      id="close-beta"
      gap={2}
    >
      <Stack flexDirection={{ xs: "column", md: "row" }} gap={1}>
        <TextField
          sx={{
            backgroundColor: "white",
            "fieldset": {
              borderColor: "common.bellflowerBlue",
              borderRadius: "10px",
            },
            borderRadius: "10px",
            height: "56px",
            flex: 1
          }}
          placeholder="Enter your email"
          {...form.register("email", {
            required: true,
          })}
          error={!!form.formState.errors.email}
          helperText={form.formState?.errors?.email?.message}
        />
        <Button
          disabled={mutate.isLoading}
          loading={mutate.isLoading}
          type="submit"
          variant="contained"
          // size="medium"
          sx={{
            height: 56,
            borderRadius: "10px",
            // ":disabled": {
            //   opacity: 0.7,
            //   background: "#CC2630",
            //   color: "white",
            //   cursor: "not-allowed",
            // },
            backgroundColor: "black",
            textTransform: "unset"
          }}
        >
          {buttonText}
        </Button>
      </Stack>
      <Stack flexDirection={"row"}>
        <RadioGroup
          defaultValue={_DEFAULT_VALUE_.type}
          name="radio-buttons-group"
          row
          value={watchRoleType}
          onChange={(e) => form.setValue("type", e.target.value)}
        >
          <FormControlLabel value="CLIENT" control={<Radio />} label="I’m a client" />
          <FormControlLabel value="FREELANCER" control={<Radio />} label="I’m a freelancer" />
        </RadioGroup>
      </Stack>
    </Box>
  );
};

export default Subscribe;
