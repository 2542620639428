import { axios, handleApiError } from "@features/config";

type Request = {
  projectId: string;
};

type Response = {
  success: boolean;
  data?: { code: number; message: string };
  message?: string;
};

export const reactionProject = async ({ projectId }: Request): Promise<Response> => {
  try {
    const { data } = await axios.post(`/round-table/${projectId}/reactions`, {});
    return { success: true, data: data?.data };
  } catch (error) {
    return handleApiError(error);
  }
};
