import { FC, useEffect, useState } from "react";

import CloseIcon from "@mui/icons-material/Close";
import PlayCircleFilledIcon from "@mui/icons-material/PlayCircleFilled";
import {
  Box,
  Grid,
  Modal,
  SxProps,
  Typography,
  IconButton,
  useTheme,
} from "@mui/material";

import { useViewport } from "@contexts/ViewportContext";

type StyleKeys = "moreImgWrapper" | "moreImgItem";

const styles: { [name in StyleKeys]?: SxProps } = {
  moreImgItem: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    color: "lightgrey",
  },
};

interface CustomImagesProp {
  imageUrls: string[];
  limitImages: number;
  imageSxProps?: SxProps;
  imageGridItem: {
    xs: number;
    tablet: number;
    lg?: number;
  };
  handleClickOnMoreImage: () => void;
}

const CustomImages: FC<CustomImagesProp> = ({
  imageUrls,
  limitImages,
  imageSxProps,
  imageGridItem,
  handleClickOnMoreImage,
}) => {
  const { isMobile } = useViewport();
  const {
    palette: { common },
  } = useTheme();

  const [selectedImg, setSelectedImg] = useState("");

  const showedImageUrls = imageUrls.slice(0, limitImages);

  useEffect(() => {
    const handleEscapeKey = (e: KeyboardEvent) => {
      if (e.key === "Enter" && selectedImg) {
        handleCloseImgModal();
      }
    };

    document.addEventListener("keydown", handleEscapeKey);
    return () => document.removeEventListener("keydown", handleEscapeKey);
  }, []);

  const handleCloseImgModal = () => {
    setSelectedImg("");
  };
  return (
    <>
      <Grid
        container
        columnGap={{ xs: 0.5, desktop: 1, lg: 1.4 }}
        rowSpacing={{ xs: 1 }}
        display="flex"
        justifyContent={isMobile ? "space-between" : "flex-start"}
      >
        {showedImageUrls.map((url, index) => {
          const isVideoLink = url.includes("img.youtube.com");
          return (
            <Grid
              key={`${index}-${url}-image`}
              item
              xs={imageGridItem.xs}
              tablet={imageGridItem.tablet}
              lg={imageGridItem.lg}
              position="relative"
              onClick={
                index === limitImages - 1
                  ? handleClickOnMoreImage
                  : () => setSelectedImg(url)
              }
            >
              {index === limitImages - 1 && imageUrls.length !== limitImages ? (
                <>
                  <Box
                    component="img"
                    alt={`${url}-image`}
                    src={url}
                    borderRadius={3}
                    sx={{
                      width: "100%",
                      height: "100%",
                      cursor: "pointer",
                      filter: "brightness(0.8)",
                      opacity: 0.8,
                      objectFit: "cover",
                      ...imageSxProps,
                    }}
                  />
                  <Box sx={styles.moreImgItem}>
                    <Typography
                      variant={isMobile ? "h5" : "h3"}
                      sx={{
                        cursor: "pointer",
                      }}
                    >
                      + {imageUrls.length - showedImageUrls.length}
                    </Typography>
                  </Box>
                </>
              ) : (
                <>
                  {isVideoLink && (
                    <IconButton
                      sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        "&:disabled svg": {
                          color: "rgba(0, 0, 0, 0.7)", // Customize the color of the PlayCircleFilled icon when disabled
                        },
                      }}
                      disabled
                    >
                      <PlayCircleFilledIcon fontSize="large" />
                    </IconButton>
                  )}
                  <Box
                    component="img"
                    alt={`${url}-image`}
                    src={url}
                    borderRadius={3}
                    sx={{
                      width: "100%",
                      height: "100%",
                      cursor: "pointer",
                      objectFit: "cover",
                      ...imageSxProps,
                    }}
                  />
                </>
              )}
            </Grid>
          );
        })}
      </Grid>

      <Modal
        open={!!selectedImg}
        onClose={handleCloseImgModal}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box position="relative">
          <Box
            component="img"
            alt={`${selectedImg}-image`}
            src={selectedImg}
            sx={{
              maxWidth: "80vw",
              borderRadius: 3,
            }}
          />
          <IconButton
            onClick={handleCloseImgModal}
            sx={{
              position: "absolute",
              top: 10,
              left: 10,
              bgcolor: common.white,
              boxShadow: 1,
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </Modal>
    </>
  );
};

export default CustomImages;
