import Axios from "axios";

import { axios, handleApiError } from "@features/config";

type UploadResponse = {
  success: boolean;
  data?: { uploadLink?: string; imageLink?: string }[];
  message?: string;
};

export const getUrls = async (fileNames: string[], endpoint?: string): Promise<UploadResponse> => {
  try {
    const { data } = await axios.post(endpoint || "/upload-url", { fileNames });
    return { success: true, data: data?.data };
  } catch (error) {
    return handleApiError(error);
  }
};

export const uploadFile = async (
  url: string,
  selectedFile: File,
  config = {}
): Promise<any> => {
  try {
    await Axios.put(url, selectedFile, config);
    return { success: true };
  } catch (error) {
    return handleApiError(error);
  }
};
