import React from "react";

import { Box, Typography, styled } from "@mui/material";

import { SidebarLogo } from "@components/icon";

const Container = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  "& .title": {
    color: theme.palette.common.gray6,
    fontSize: 20,
    marginLeft: 12,
    fontWeight: 600,
  },
}));

const SidebarLogoDrawer: React.FC = () => {
  return (
    <Container>
      <SidebarLogo />
      <Typography className="title">Roundtables</Typography>
      <Box bgcolor={"primary.main"} py={0.3} px={1} borderRadius={1} ml={1}><Typography fontSize={11} fontWeight={"bold"}>BETA</Typography></Box>
    </Container>
  );
};

export default SidebarLogoDrawer;
