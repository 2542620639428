import React, { useMemo, useState } from "react";

import { Typography } from "@mui/material";
import EllipsisContent from "@components/EllipsisContent";

const MAX_DESCRIPTION_LENGTH = 78;

interface IReadMoreTextProps {
  text: string;
  maxLength?: number;
  fontSize?: string;
}

const ReadMoreText: React.FC<IReadMoreTextProps> = (props) => {
  const { text, maxLength = MAX_DESCRIPTION_LENGTH, fontSize = "10px" } = props;

  const [isFullDescription, setIsFullDescription] = useState(false);

  const formattedDescription = useMemo(() => {
    if (text?.length > maxLength && !isFullDescription) {
      return `${text.slice(0, maxLength)}...`;
    }
    return text;
  }, [text, isFullDescription, maxLength]);

  return (
    <Typography
      sx={{
        fontSize,
        fontWeight: 400,
      }}
    >
      {/* {formattedDescription} */}
      <EllipsisContent
        fontSize={fontSize}
        lineHeight={1.5}
        fontWeight={400}
        numberOfLines={"unset"}
        sx={{ wordBreak: "break-word", textAlign: "left", m: 0 }}
        content={formattedDescription}
      />
      {text?.length > maxLength ? (
        <Typography
          component="span"
          sx={{
            fontSize,
            fontWeight: 400,
            color: "primary.main",
            ml: "2px",
            cursor: "pointer",
          }}
          onClick={() => setIsFullDescription((prev) => !prev)}
        >
          {isFullDescription ? "Read Less" : "Read More"}
        </Typography>
      ) : null}
    </Typography>
  );
};

export default ReadMoreText;
