import { FC, useCallback, useEffect, useState } from "react";
import {
  AiOutlineCamera,
  AiOutlineCloseCircle,
  AiOutlineFileImage,
} from "react-icons/ai";

import { Avatar, Box, Grid, IconButton, Link, Stack, Typography } from "@mui/material";

import {
  toBase64,
  ACCEPTED_FILE_TYPE,
} from "@components/MultiFileUploader/MultiFileUploader";

export enum ImageUploaderType {
  AVATAR = "AVATAR",
  RECTANGLE = "RECTANGLE",
  ROUNDED = "ROUNDED",
}

interface IProps {
  initAvatar?: string | File;
  onSetValue: (image: File) => void;
  isViewOnly?: boolean;
  type?: ImageUploaderType;
  variant?: "square" | "rounded" | "circular";
}

const ImageUploader: FC<IProps> = ({
  initAvatar = "",
  onSetValue,
  isViewOnly,
  type = ImageUploaderType.AVATAR,
  variant,
}) => {
  const [avatar, setAvatar] = useState<any>(initAvatar);

  const handleUploadImage = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (!e.currentTarget.files || !e.currentTarget.files[0]) {
        return;
      }
      const file = e.currentTarget.files[0];
      const fileReader = new FileReader();

      fileReader.addEventListener("load", function (e) {
        setAvatar(e?.target?.result);
      });

      fileReader.readAsDataURL(file);

      onSetValue(file);
    },
    [onSetValue]
  );

  useEffect(() => {
    if (initAvatar) {
      toBase64([initAvatar]).then((fileContent) => {
        setAvatar(fileContent?.images?.[0] || "");
      });
    }
  }, [initAvatar]);

  return [ImageUploaderType.AVATAR, ImageUploaderType.ROUNDED].includes(type) ? (
    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
      <Box sx={{ position: "relative" }}>
        <Avatar
          variant={variant}
          alt="Avatar"
          src={avatar}
          sx={{
            width: 120,
            height: 120,
            backgroundColor: !avatar && type === ImageUploaderType.ROUNDED && "white",
          }}
        />

        <IconButton
          component="label"
          sx={{
            position: "absolute",
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            backgroundColor:
              !avatar && type === ImageUploaderType.ROUNDED
                ? "white"
                : "rgba(0, 0, 0, 0.04)",
            borderRadius: variant === "rounded" ? "4px" : "100%",
          }}
        >
          {!isViewOnly && (
            <Stack
              sx={{
                position: "absolute",
                color: "#0000004D",
                alignItems: "center",
                bottom: type === ImageUploaderType.ROUNDED ? "30%" : 10,
              }}
            >
              <AiOutlineCamera
              // style={{ position: "absolute", bottom: 10 }}
              />
              {!avatar && type === ImageUploaderType.ROUNDED && (
                <Typography fontSize={14}>Add Avatar</Typography>
              )}
            </Stack>
          )}
          <input
            style={{ display: "none" }}
            type="file"
            accept={ACCEPTED_FILE_TYPE.image}
            hidden
            onChange={handleUploadImage}
            name="image"
            disabled={isViewOnly}
          />
        </IconButton>
      </Box>
    </Box>
  ) : (
    <Stack spacing={2}>
      <Stack
        spacing={2}
        sx={{
          alignItems: "center",
          p: 3,
          border: (theme) => `1px dashed ${theme.palette.common.bellflowerBlue}`,
        }}
      >
        <AiOutlineFileImage style={{ width: 40, height: 40, color: "#e2e8f0" }} />

        <Stack direction="row" spacing={0.5} sx={{ alignItems: "center", fontSize: 14 }}>
          <Link component="label" sx={{ cursor: "pointer" }}>
            Click here
            <input
              hidden
              multiple={false}
              type="file"
              accept=".png, .jpg, .jpeg"
              onChange={handleUploadImage}
              name="image"
              style={{ display: "none" }}
            />
          </Link>
          <Typography>to upload</Typography>
        </Stack>
      </Stack>

      {avatar && (
        <Grid container spacing={1}>
          <Grid item xs={3}>
            <Box sx={{ position: "relative" }}>
              <IconButton
                onClick={() => setAvatar(null)}
                sx={{ position: "absolute", top: 0, right: 0, zIndex: 10 }}
              >
                <AiOutlineCloseCircle
                  style={{
                    backgroundColor: "white",
                    color: "red",
                    borderRadius: "100%",
                  }}
                />
              </IconButton>
              <Avatar
                variant="rounded"
                src={avatar}
                sx={{ width: "100%", height: 120 }}
              />
            </Box>
          </Grid>
        </Grid>
      )}
    </Stack>
  );
};

export default ImageUploader;
