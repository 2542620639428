import { ReactNode } from "react";
import { Route } from "react-router-dom";

import { AppRoute } from "./type";

export const getAllPaths = (
  ele: AppRoute,
  arr: string[] = [],
  parentPath?: string
): string[] => {
  const pathName = parentPath ? `${parentPath}${ele.path}` : ele.path;

  arr.push(pathName);

  if (ele.nestedPaths?.length) {
    ele.nestedPaths.map((ele) => getAllPaths(ele, arr, pathName));
  }

  return arr;
};

export const generateAppRoutes = (
  ele: AppRoute,
  arr: ReactNode[] = [],
  parent?: AppRoute
) => {
  const component = parent ? (
    parent.path === "nothing" || ele.path === "nothing" ? null : (
      <Route key={parent.path} path={parent.path} element={parent.component}>
        <Route
          index={Boolean(ele.index)}
          key={parent.path + ele.path}
          path={ele.path}
          element={ele.component}
        />
      </Route>
    )
  ) : (
    <Route
      index={Boolean(ele.index)}
      key={ele.path}
      path={ele.path}
      element={ele.component}
    />
  );

  arr.push(component);

  if (ele.nestedPaths?.length) {
    ele.nestedPaths.map((subEle) => generateAppRoutes(subEle, arr, ele));
  }

  return arr;
};
