import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { reactionProject } from "@api/roundtable/reactionProject";
import { ThumbUpAltOutlined } from "@mui/icons-material";
import { IconButton, Tooltip, Typography, useTheme } from "@mui/material";
import { AuthPath } from "@routers/path/AuthPath";

import { useAppSelector } from "@app/hooks";

import { useSnackbar } from "@contexts/SnackbarContext";
import { useViewport } from "@contexts/ViewportContext";

import queryClient from "@config/queryClient";

export interface IProps {
  likeNumber: string;
  liked: boolean;
  projectId: string;
}
let timeOutReact: any = 0;

export default function ReactionButton(props: IProps) {
  const { liked, likeNumber, projectId } = props;
  const { isDesktop } = useViewport();
  const [displayLike, setDisplayLike] = useState({ liked, like: likeNumber || "0" });
  const { userInfo } = useAppSelector((state) => state.auth);
  const navigate = useNavigate();
  const {
    palette: { primary, common },
  } = useTheme();
  const { setSnackbar } = useSnackbar();

  useEffect(() => {
    if (likeNumber === displayLike.like || !userInfo) {
      return;
    }
    setDisplayLike({ liked, like: likeNumber });
  }, [liked, likeNumber]);

  const onReactProject = () => {
    if (!userInfo?.idToken) {
      navigate(AuthPath.signIn);
      return;
    }
    const delta = displayLike.liked ? -1 : 1;
    setDisplayLike((prev) => ({ like: `${+prev.like + delta}`, liked: !prev.liked }));
    handleReaction();
  };

  const handleReaction = async () => {
    if (timeOutReact) {
      clearTimeout(timeOutReact);
    }

    timeOutReact = setTimeout(async () => {
      if (liked !== displayLike.liked) {
        return;
      }
      const { success, message } = await reactionProject({
        projectId,
      });

      if (success) {
        queryClient.invalidateQueries(["getPublishedProjects"]);
      } else {
        setSnackbar({
          message,
          open: true,
          severity: "error",
        });
      }
    }, 1000);
  };
  return (
    <IconButton
      sx={{
        height: isDesktop ? 40 : 32,
        border: `1px solid ${displayLike.liked ? primary.main : common.featherSoftBlue}`,
        borderRadius: "8px",
        gap: "8px",
        color: displayLike.liked ? primary.main : common.gray4,
      }}
      onClick={onReactProject}
    >
      <Typography>{displayLike.like}</Typography>
      <Tooltip title="Like" placement="top" arrow>
        <ThumbUpAltOutlined
          fontSize="medium"
          sx={{
            color: displayLike.liked ? primary.main : common.gray4,
            cursor: "pointer",
          }}
        />
      </Tooltip>
    </IconButton>
  );
}
