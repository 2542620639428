
import { forwardRef, Ref } from "react";
import { Button, ButtonProps, CircularProgress, CircularProgressProps, Stack } from "@mui/material";
// import { OverridableStringUnion } from "@mui/types"
// import { CircularProgressPropsColorOverrides } from "@mui/material/CircularProgress"

export interface IProps extends ButtonProps {
  loading?: boolean;
  loadingProps?: CircularProgressProps;
}

export default forwardRef(function CustomButton(
  { loading, loadingProps = {}, children, disabled, variant, ...rest }: IProps,
  ref: Ref<HTMLButtonElement>
) {
  return (
    <Button ref={ref} {...rest} disabled={loading || disabled} variant={variant || "contained"} sx={{ ...rest.sx, position: "relative" }}>
      {loading && (
        <Stack sx={{ position: "absolute", top: "50%", transform: "translateY(-50%)" }}><CircularProgress {...loadingProps} size={loadingProps.size || 22} /></Stack>
      )}
      <Stack visibility={loading ? "hidden" : "initial"}>{children}</Stack>
    </Button>
  )
})
