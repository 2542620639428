import { Stack, Grid, Chip, SvgIcon } from "@mui/material";
import Typography from "./GatherlanceTypography"

import { ReactComponent as ScrollIcon } from "@assets/images/gatherlance-landing/scroll.svg";
import { ReactComponent as ColorSwatchIcon } from "@assets/images/gatherlance-landing/color-swatch.svg";
import { ReactComponent as StatusUpIcon } from "@assets/images/gatherlance-landing/status-up.svg";
import { ReactComponent as MessageFavoriteIcon } from "@assets/images/gatherlance-landing/message-favorite.svg";

const data = [
  {
    icon: <SvgIcon sx={{ height: 28, width: 28 }}><ScrollIcon /></SvgIcon>,
    title: "Programming & Tech",
    description: "235 services"
  },
  {
    icon: <SvgIcon sx={{ height: 28, width: 28 }}><ColorSwatchIcon /></SvgIcon>,
    title: "Design & Creative",
    description: "982 services"
  },
  {
    icon: <SvgIcon sx={{ height: 28, width: 28 }}><StatusUpIcon /></SvgIcon>,
    title: "Sales & Marketing",
    description: "123 services"
  },
  {
    icon: <SvgIcon sx={{ height: 28, width: 28 }}><MessageFavoriteIcon /></SvgIcon>,
    title: "Admin & Customer Support",
    description: "205 services"
  }
];

const Section5 = () => {
  return (
    <Stack gap={10} py={{ xs: 8, md: 10 }}>
      <Stack gap={2}>
        <Chip label="Our services" sx={{ width: "max-content", bgcolor: "#EDF2F7" }} />
        <Typography fontWeight={600} fontSize={{ xs: 30, md: 48 }} lineHeight={{ xs: "38px", md: "60px" }}>We are the future of the work</Typography>
        <Typography fontSize={{ xs: 16, md: 20 }} lineHeight={{ xs: "24px", md: "30px" }} color={"common.gray6"}>{`We believe in a future that AI will free lots of the labor and no one need to work for full time (except high tech industry).`}</Typography>
        <Typography fontSize={{ xs: 16, md: 20 }} lineHeight={{ xs: "24px", md: "30px" }} color={"common.gray6"} maxWidth={904}>{`Gatherlance is our attempt to  bridge the gap between hiring freelance and employee.`}</Typography>
      </Stack>

      <Grid container spacing={4}>
        {data.map((content, i) => (
          <Grid key={i} item xs={12} md={6} lg={3} sx={{ flex: 1 }}>
            <Stack sx={{ px: 2, py: 7, borderRadius: 4, boxShadow: "0px 187px 52px 0px rgba(186, 186, 186, 0.00), 0px 119px 48px 0px rgba(186, 186, 186, 0.01), 0px 67px 40px 0px rgba(186, 186, 186, 0.05), 0px 7px 16px 0px rgba(186, 186, 186, 0.10)", display: "flex", flexDirection: "column", gap: 2, alignItems: "center" }}>
              {content.icon}
              <Typography fontSize={18} textAlign={"center"}>{content.title}</Typography>
              <Typography fontSize={14} textAlign={"center"} color={"common.gray5"}>{content.description}</Typography>
            </Stack>
          </Grid>
        ))}
      </Grid>
    </Stack>
  )
}

export default Section5;
