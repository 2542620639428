import { useEffect, useMemo, useState } from "react";
import { Outlet, useLocation, useNavigate, useSearchParams } from "react-router-dom";

import { Category_List } from "@constants/category";
import CloseIcon from "@mui/icons-material/Close";
import LogoutIcon from "@mui/icons-material/Logout";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  Button,
  Divider,
  IconButton,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { AuthPath } from "@routers/path/AuthPath";
import { ProtectedPaths } from "@routers/path/ProtectedPath";
import { PublicPaths } from "@routers/path/PublicPath";

import useWindowScroll from "@hooks/useWindowScroll";

import { useAppDispatch, useAppSelector } from "@app/hooks";

import { signOut } from "@features/auth/slice";

import CreateProject from "@components/CreateProject";
import DocumentHeader from "@components/DocumentHeader";

import { addNewUrlParams } from "@utils/helpers/stringHelper";

import RoundtableHorizontalMainColor from "@assets/roundtable/SVG/Roundtables/RoundtableProjects-Horizontal-MainColor.svg";
import RoundtableProductsLogoHorizontalMainColor from "@assets/roundtable/PNG/Roundtables/RoundtableProducts-Horizontal-MainColor.png";

import { useViewport } from "@contexts/ViewportContext";

import { CategoryMenu } from "./components/CategoryMenu";
import HeadingSearchBar from "./components/HeadingSearchBar";
import LandingFooter from "./components/LandingFooter";

const HEADER_HEIGHT = 76;
const FAVICON_NEW = "/favicon_head_new.png";

const SignInOutBtn = ({
  type = "primary",
}: {
  type: "primary" | "secondary" | "custom";
}) => {
  const { userInfo } = useAppSelector((state) => state.auth);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {
    palette: { primary, common },
  } = useTheme();

  const isLoggedIn = useMemo(() => {
    if (userInfo) {
      return true;
    }

    return false;
  }, [userInfo]);

  const handleClickSignInAndSignOut = () => {
    if (isLoggedIn) {
      dispatch(signOut());
      navigate(AuthPath.signIn);
    }

    return navigate(
      addNewUrlParams(AuthPath.signIn, {
        redirectTo: PublicPaths.projectPage,
      })
    );
  };

  useEffect(() => {
    const link: HTMLLinkElement = document.querySelector("link[rel~='icon']");
    link.rel = "icon";
    link.setAttribute("href", `${FAVICON_NEW}?v=${Date.now()}`);
  }, []);

  return (
    <>
      {/* {isLoggedIn && <DocumentHeader />} */}

      <Button
        variant={type === "primary" ? "contained" : "text"}
        sx={{
          textTransform: "unset",
          bgcolor:
            type === "custom" ? (isLoggedIn ? common.white : primary.main) : "unset",
          color: type === "custom" ? (isLoggedIn ? primary.main : common.white) : "unset",
          border: type === "custom" && isLoggedIn ? `1px solid ${primary.main}` : "none",
          boxShadow: "none",
        }}
        onClick={handleClickSignInAndSignOut}
        startIcon={!isLoggedIn && <LogoutIcon />}
        endIcon={isLoggedIn && <LogoutIcon />}
      >
        {isLoggedIn
          ? `${userInfo?.user?.name || userInfo?.user?.username} - Log out`
          : "Log in"}
      </Button>
    </>
  );
};

const LandingLayout = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const scrollPosition = useWindowScroll();
  const {
    palette: { common },
  } = useTheme();

  const { userInfo } = useAppSelector((state) => state.auth);
  const isLoggedIn = useMemo(() => {
    if (userInfo) {
      return true;
    }

    return false;
  }, [userInfo]);

  const { isMobile, isTablet } = useViewport();
  const [searchParams] = useSearchParams();
  const isMobileDesign = isMobile || isTablet;
  const isProductPath = location.pathname?.startsWith(PublicPaths.product);
  const isRelatedProductPage = location.pathname?.startsWith(PublicPaths.publishedProjects) && searchParams.get("category")?.toLowerCase()?.includes(Category_List.ComputerProduct?.toLowerCase())
  // const { data: publishedProjects } = useGetPublishedProjects({
  //   page: 0,
  //   pageSize: 9999,
  //   type: "popular",
  // });

  const [isExpandedSearchBar, setIsExpandedSearchBar] = useState(false);

  const { pathname } = useLocation();
  useEffect(() => {
    handleCloseSearchBar();
  }, [pathname]);

  const expandSearchBar = () => {
    setIsExpandedSearchBar(true);
  };

  const handleCloseSearchBar = () => {
    setIsExpandedSearchBar(false);

    if (isMobileDesign) {
      toggleMobileMenu(false);
    }
  };

  const onNavigateProject = () => {
    isLoggedIn
      ? window.open(ProtectedPaths.myProjects, "_blank")
      : navigate(AuthPath.signIn);
  };

  const onNavigateCloseBeta = () => {
    // document.querySelector("#close-beta")?.scrollIntoView({
    //   behavior: "smooth",
    // });
    window.open("https://www.roundtables.app/", "_blank");
  };

  const [showMobileMenu, setShowMobileMenu] = useState(false);

  const toggleMobileMenu = (state?: boolean) => {
    setShowMobileMenu((prevState) => state ?? !prevState);
  };

  const isHiddenCategory =
    [PublicPaths.product].includes(location.pathname as any) ||
    location.pathname.includes(PublicPaths.product);
  const shouldHiddenSearchBar = location.pathname.includes(PublicPaths.publishedProjects);

  return (
    <Stack>
      <Box
        sx={{
          position: scrollPosition <= HEADER_HEIGHT ? "static" : "fixed",
          top: 0,
          left: 0,
          zIndex: 10,
          background: common.white,
          boxSizing: "border-box",
          width: "100%",
        }}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          height={HEADER_HEIGHT}
          px={isMobile ? 2 : isTablet ? 5 : 10}
          borderBottom={isMobileDesign ? "none" : common.border}
        >
          <Box
            sx={{ cursor: "pointer" }}
            flexDirection="row"
            display="flex"
            alignItems="center"
            gap={1}
          >
            <Box onClick={() => navigate(isProductPath ? PublicPaths.product : PublicPaths.projectPage)}>
              <img
                src={(isProductPath || isRelatedProductPage) ? RoundtableProductsLogoHorizontalMainColor : RoundtableHorizontalMainColor}
                alt="heading-logo"
                height={isMobileDesign ? 32 : 40}
              />
            </Box>
            {!isHiddenCategory && <CategoryMenu />}
          </Box>

          {isMobileDesign ? (
            <IconButton onClick={() => toggleMobileMenu()} sx={{ px: 0 }}>
              {showMobileMenu ? <CloseIcon /> : <MenuIcon />}
            </IconButton>
          ) : (
            <Box
              display="flex"
              columnGap={2}
              alignItems="center"
              sx={{
                "& .MuiButton-text": {
                  color: "common.black",
                  fontSize: 15,
                  padding: "5px",
                  fontWeight: 400,
                },
              }}
            >
              {!shouldHiddenSearchBar && (
                <>
                  {isExpandedSearchBar ? (
                    <HeadingSearchBar
                      // publishedProjects={publishedProjects}
                      onClose={handleCloseSearchBar}
                    />
                  ) : (
                    <Button endIcon={<SearchIcon />} onClick={expandSearchBar}>
                      Search
                    </Button>
                  )}
                </>
              )}

              <CreateProject
                btnProps={{
                  sx: {
                    color: "common.black",
                    background: "white",
                    boxShadow: "none",
                    ":hover": {
                      boxShadow: "none",
                      background: "#cc26300a",
                    },
                    "& p": {
                      fontWeight: 400,
                      fontSize: "15px",
                    },
                  },
                }}
                text={`${isProductPath ? "Post product" : "Create project"}`}
                {...(location.pathname.includes(PublicPaths.product) && {
                  defaultValues: {
                    category: Category_List.ComputerProduct,
                  },
                  fixedCategory: true,
                })}
              />
              <Button
                sx={{
                  fontWeight: "normal",
                  padding: "5px",
                  fontSize: 15,
                }}
                onClick={onNavigateCloseBeta}
              >
                Join close beta
              </Button>
              <Button
                sx={{
                  fontWeight: "normal",
                  padding: "5px",
                  fontSize: 15,
                }}
                onClick={onNavigateProject}
              >
                {`My ${isProductPath ? "Product" : "Project"}`}
              </Button>
              <Divider orientation="vertical" variant="middle" flexItem />
              <SignInOutBtn type="secondary" />
            </Box>
          )}
        </Box>

        {isMobileDesign && showMobileMenu && (
          <Stack rowGap={2} p={3}>
            <HeadingSearchBar
              // publishedProjects={publishedProjects}
              onClose={handleCloseSearchBar}
              fullWidth
            />
            <CreateProject
              btnProps={{
                sx: {
                  color: "common.black",
                  background: "white",
                  boxShadow: "none",
                  width: "100%",
                  "& p": {
                    fontWeight: 400,
                    fontSize: "15px",
                  },
                },
              }}
              text="Create project"
            />
            <Button
              sx={{
                fontWeight: "normal",
                padding: "5px",
                color: "common.black",
                fontSize: 15,
              }}
              onClick={onNavigateCloseBeta}
            >
              Join close beta
            </Button>
            <Button
              sx={{
                fontWeight: "normal",
                padding: "5px",
                color: "common.black",
                fontSize: 15,
              }}
              onClick={onNavigateProject}
            >
              My Project
            </Button>
            <SignInOutBtn type="custom" />
          </Stack>
        )}
      </Box>

      <Outlet />
      <LandingFooter />
    </Stack>
  );
};

export default LandingLayout;
