
import {
  Stack,
  // TextField, Button
} from "@mui/material";
import { Search as SearchIcon } from "@mui/icons-material";
import Typography from "./GatherlanceTypography";

import Subscribe from "./Subscribe";

import heroImg from "@assets/images/gatherlance-landing/hero.png";
import heroMobileImg from "@assets/images/gatherlance-landing/m_hero.png";

import { useViewport } from "@contexts/ViewportContext";

const HeroSection = () => {
  const { isMobile } = useViewport();

  return (
    <Stack alignItems={"center"} py={{ xs: 8, md: 10 }} gap={6}>
      <Stack maxWidth={606} gap={5}>
        <img width={"100%"} src={isMobile ? heroMobileImg : heroImg} alt="hero" />
        {/* <Typography color={"common.gray5"}>Lorem ipsum dolor sit amet consectetur. Purus nullam et egestas non amet libero pulvinar amet consectetur molestie.</Typography> */}
      </Stack>
      {/* <Stack width={{ xs: "100%", md: 560 }} gap={2} alignItems={"center"}>
        <Stack flexDirection={"row"} gap={1} width={"100%"}>
          <TextField
            sx={{
              backgroundColor: "white",
              "fieldset": {
                borderColor: "common.bellflowerBlue",
                borderRadius: "10px",
              },
              borderRadius: "10px",
              height: "56px",
              flex: 1,
              "svg": {
                color: "#718096",
                pr: 1,
              },
            }}
            InputProps={{
              startAdornment: <SearchIcon />,
            }}
            placeholder="Search for services, industry, team,..."
          />
          <Button
            variant="contained"
            // size="medium"
            sx={{
              height: 56,
              borderRadius: "10px",
              backgroundColor: "black",
              textTransform: "unset",
              px: "26px"
            }}
          >
            {"Search"}
          </Button>
        </Stack>
        <Typography color={"common.gray5"}>or</Typography>
        <Button
          variant="contained"
          sx={{
            height: 56,
            width: { xs: "100%", md: "244px" },
            borderRadius: "10px",
            backgroundColor: "black",
            textTransform: "unset",
          }}
        >
          {"Post a job"}
        </Button>
      </Stack> */}
      <Stack width={{ xs: "100%", md: 468 }}><Subscribe style="big" buttonText="Sign up for early access" /></Stack>
    </Stack>
  )
}

export default HeroSection;
